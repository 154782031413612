import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import axios from "axios";

export default class Companies extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: {
      success: true,
      list:[],
      posts:0,
      companies:0
    },
    search: "",
    searchTerm: ""
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.search();
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/position/"+id,
      job: job
    })
  }

  search() {
    this.setState({searchTerm: this.state.search});
    let data = new FormData();
    data.append("query", this.state.search);
    data.append("tansitmethod", "NONE");
    data.append("traffic", "TRAFFIC_FREE");
    data.append("traveltime", "");
    data.append("category", "");
    data.append("compensation", "NONE");

    axios.post("https://l16.vixo.no/api/public/v2/get/user/jobs", data).then(res => {
      this.setState({results: res.data});
    });
  }

  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>

          <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
            <Card.Body>
              <Card.Title className={this.state.results.list.length == 0 ? 'hidden':''}>Utlysninger</Card.Title>
              <Card.Subtitle className={`mb-2 text-muted ${this.state.searchTerm == "" ? 'hidden':''}`}>Viser {this.state.results.list.length} treff på "<i>{this.state.searchTerm}</i>"</Card.Subtitle>
              <Card.Text>

                <div className="searchWrapper">

                  <div className="searchContainer">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control type="text" placeholder="F.eks IT, Apputvikler, Account manager, Olje" value={this.state.search} onChange={(e) => {this.setState({search: e.target.value})}} />
                    </Form.Group>
                  </div>

                  <div className="searchBtn">
                    <Button variant="primary" onClick={this.search}>
                      Søk
                    </Button>
                  </div>

                </div>
                <ListGroup>

                       {this.state.results.list.map((item) => (
                            <ListGroup.Item className="listGroupSegments" onClick={(e) => {this.openPosition(item.id, item)}}>
                              <div className="lgLogo" style={{backgroundImage: `url(https://l16.vixo.no/api/public/get/image/${item.logo})`}}></div>
                              <div className="lgContent">
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                              </div>

                              <div className={`lgAction ${item.isAd == false ? 'hidden' : ''}`}>
                                Reklame
                              </div>
                            </ListGroup.Item>
                          )
                        )}

                </ListGroup>

                <div className={this.state.results.list.length == 0 ? '':'hidden'} style={{"textAlign": "center"}}>
                  <h3>Oida! Her var det ikke mange utlysninger, prøv igjen senere!</h3>
                  <p>Kanskje du ønsker å se over profilen din, en ryddig og oppdatert profil er viktig!</p>
                  <p style={{"paddingLeft": "20%", "paddingRight": "20%"}}>Du kan motta meldinger selvom det ikke er noen aktive utlysninger i portalen, hodejegere kan søke etter kompetanse på stillinger som ikke ligger offentlig.</p>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
