import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {BriefcaseIcon, MortarBoardIcon, AlertIcon, PlusSmallIcon} from 'react-octicons'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default class Company extends Component<Props> {
  state = {
    showAddTimeline: false,
    showEditProfile: false,
    profile: {
      created: "",
      employees: "",
      id: "",
      ingress: "",
      location: "",
      logo: "",
      name: "",
      text: "",
      vision: "",
      why: ""
    }
  }

  constructor(props) {
    super(props);

    this.getProfile = this.getProfile.bind(this);
    this.toggleTimelineAdd = this.toggleTimelineAdd.bind(this);
    this.toggleEditProfile = this.toggleEditProfile.bind(this);
  }

  componentDidMount() {
    this.getProfile();
    console.log("Load");
  }

  getProfile() {
    axios.get("https://l16.vixo.no/api/public/get/Company/"+this.props.match.params.url).then(res => {
      this.setState({profile: res.data});
    });
  }

  toggleTimelineAdd() {
    console.log(this.state.showAddTimeline);
    if(this.state.showAddTimeline == true) {
      this.setState({showAddTimeline: false});
    } else {
      this.setState({showAddTimeline: true});
    }
  }

  toggleEditProfile() {
    console.log(this.state.showEditProfile);
    if(this.state.showEditProfile == true) {
      this.setState({showEditProfile: false});
    } else {
      this.setState({showEditProfile: true});
    }
  }

  render() {
    return(
      <div>

                <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
                  <Card.Body>
                    <div className="positionWrapper">
                      <div className="company">

                        <div className={this.state.showEditProfile == false ? '':'hidden'}>
                          <center>
                            <img width="150px" onClick={this.toggleEditProfile} src="https://www.orkla.com/bundles/orklaonebase/built/corporatecom/logo-orkla-tagline-com-98680d1e1538648a6be55c64a97de1b5.svg" />
                            <h3 style={{marginTop: "20px"}}>{this.state.profile.firstname} {this.state.profile.surname}</h3>
                          </center>
                          <p>{this.state.profile.biography}</p>
                        </div>

                      </div>
                      <div className="position">


                        <div className="references">
                          <h3>Referanser</h3>
                        </div>


                      </div>
                    </div>
                  </Card.Body>
                </Card>

      </div>
    );
  }
}
