import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class Membership extends Component<Props> {

  state = {
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  stateChange(e){
    console.log(e);
  }

  render() {
    return(
      <div className="login-wrapper">
        <Card style={{ width: '100%' }}>
          <Card.Body>
              <h3>Du har gratis medlemsskap!</h3>
              <p>Vi har valgt å gjøre portalen gratis under utbruddet av Covid-19, så du kan være klar når arbeidsmarkedet åpner seg igjen!</p>
              <p>Bruk denne tiden til å lage en attraktiv profil på The Connector!</p>
              <p>Mvh, Kjell-Ivar Sormerud!</p>
          </Card.Body>
        </Card>

      </div>
    );
  }
}
