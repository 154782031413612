import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import axios from "axios";

export default class RecruiterSearch extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: {
      success: true,
      list:[],
      posts:0,
      companies:0
    },
    empty: {
      list: []
    },
    search: "",
    searchTerm: "",
    toggleFilter: false,
    counties: [],
    municipalities: [],

    county: 0,
    chosenMunicipalities: []
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.search = this.search.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.getCounties = this.getCounties.bind(this);
    this.chooseCounty = this.chooseCounty.bind(this);
    this.chooseMunicipality = this.chooseMunicipality.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.search();
    this.getCounties();
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/profile/"+job.url,
      profile: job
    })
  }

  search() {
    this.setState({searchTerm: this.state.search});
    let data = new FormData();
    data.append("query", this.state.search);

    axios.post("https://l16.vixo.no/api/public/v2/search/users", data).then(res => {
      this.setState({results: res.data});
    });
  }

  toggleFilter() {
    if(this.state.showFilter == true) {
      this.setState({showFilter: false});
    } else {
      this.setState({showFilter: true});
    }
  }

  getCounties() {
    axios.get("https://l16.vixo.no/api/public/get/location/counties").then(res => {
      this.setState({counties: res.data});
    })
  }

  chooseCounty(id) {
    this.setState({county: id});
    axios.get("https://l16.vixo.no/api/public/get/location/municipalities?county=" + id).then(res => {
      this.setState({municipalities: res.data});
      this.setState({chosenMunicipalities: []});
    })
  }

  chooseMunicipality(id) {
    console.log("Yeet");
    let chosen = this.state.chosenMunicipalities;

    if(chosen.includes(id)) {
      chosen.remove(id);
      this.setState({chosenMunicipalities: chosen});
    } else {
      chosen.push(id);
      this.setState({chosenMunicipalities: chosen});
    }


  }

  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>

          <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
            <Card.Body>
              <Card.Title className={this.state.results.list.length == 0 ? 'hidden':''}>Jobbsøkere</Card.Title>
              <Card.Subtitle className={`mb-2 text-muted ${this.state.searchTerm == "" ? 'hidden':''}`}>Viser {this.state.results.list.length} treff på "<i>{this.state.searchTerm}</i>"</Card.Subtitle>
              <Card.Text>

                <div className="searchWrapper">

                  <div className="searchContainer">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control type="text" placeholder="F.eks IT, Apputvikler, Account manager, Olje" value={this.state.search} onChange={(e) => {this.setState({search: e.target.value})}} />
                    </Form.Group>
                  </div>

                  <div className="searchBtn">
                    <Button variant="primary" onClick={this.search}>
                      Søk
                    </Button>
                  </div>

                  <div className="filter noselect" onClick={this.toggleFilter}>
                    Filter <span className={this.state.showFilter?'':'hidden'}>↓</span><span className={this.state.showFilter?'hidden':''}>↑</span>
                  </div>

                  <div className={`filterMenu ${this.state.showFilter ? '':'hidden'}`}>
                    <h4>Fylke:</h4>
                    <div className="counties">
                      {this.state.counties.map((county) => (
                        <div className={`county noselect ${this.state.county == county.id ? 'active':''}`} onClick={(e) => { this.chooseCounty(county.id) }}>{county.county}</div>
                      ))}
                    </div>

                    <h4 className={this.state.county != 0 ? '':'hidden'}>Kommune:</h4>
                    <div className={`municipalities counties ${this.state.county != 0 ? '':'hidden'}`}>
                      {this.state.municipalities.map((municipality) => (
                        <div className={`county noselect ${this.state.chosenMunicipalities.includes(municipality.id) ? 'active' : ''}`} onClick={(e) => { this.chooseMunicipality(municipality.id) }}>{municipality.municipality}</div>
                      ))}

                    </div>
                  </div>

                </div>
                <ListGroup>
                       {this.state.results.list.map((item) => (
                            <ListGroup.Item className="listGroupSegments" onClick={(e) => {this.openPosition(item.id, item)}}>
                              <div className="lgLogo" style={{backgroundImage: `url(https://l16.vixo.no/api/public/get/image/${item.profilepicture})`}}></div>
                              <div className="lgContent">
                                <h4>{item.name}</h4>
                                <p>{item.biography}</p>
                              </div>
                            </ListGroup.Item>
                          )
                        )}

                </ListGroup>

                <div className={this.state.results.list.length == 0 ? '':'hidden'} style={{"textAlign": "center"}}>
                  <h3>Oida! Her var det ikke mange jobbsøkere!</h3>
                  <p>Prøv et annet søk!</p>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
