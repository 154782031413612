import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import { slide as Menu } from 'react-burger-menu'
import {isMobile} from "react-device-detect";

import Login from './pages/login/login.jsx';
import Register from './pages/login/register.jsx';
import Recover from './pages/forgot/recover.jsx';
import Forget from './pages/forgot/forgot.jsx';

import Home from './pages/home/home.jsx';
import Position from './pages/position/position.jsx';
import Profile from './pages/profile/profile.jsx';
import Messages from './pages/messages/messages.jsx';
import AnonProfile from './pages/profile/anonProfile.jsx';

import Membership from './pages/membership/membership.jsx';
import Companies from './pages/companies/companies.jsx';
import Company from './pages/companies/company.jsx';
import Favorites from './pages/favorites/favorites.jsx';

import RecruiterPositions from './pages/recruiter/positions/positions.jsx';
import RecruiterPosition from './pages/recruiter/position/position.jsx';
import RecruiterCreatePosition from './pages/recruiter/createposition/createposition.jsx';
import RecruiterSearch from './pages/recruiter/search/search.jsx';

import AdminStatistics from './pages/admin/statistics/statistics.jsx';
import AdminUsers from './pages/admin/users/users.jsx';

import BlockCompany from './pages/blockCompany/blockCompany.jsx';

import logo from "./images/logo.png"

import userMenu from "./menus/user/menu.jsx";

import MessengerCustomerChat from 'react-messenger-customer-chat';

import GoogleAnalytics from './GoogleAnalytics.jsx';

class App extends React.Component {

  state = {
    userdata: {},
    loaded: false,
    showMenu: false,
    renderMobileMenu: false
  }

  constructor(props) {
    super(props);

    this.menuRender = this.menuRender.bind(this);
    this.loadUserData = this.loadUserData.bind(this);

    axios.defaults.headers.common["token"] = localStorage.getItem("token");
    console.log("Should work");

    let event = new Event('loadUserData');
    window.addEventListener('loadUserData', this.loadUserData, false);

    this.updatePredicate = this.updatePredicate.bind(this);
    this.renderMobileMenu = this.renderMobileMenu.bind(this);
  }

  componentDidMount() {
    this.loadUserData();

    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    let event = new Event('loadUserData');
    window.dispatchEvent(event)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  loadUserData() {
    axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
      let data = res.data;

      if(res.data.success == true) {
        this.setState({userdata: res.data});
        this.setState({isLoggedIn: true});
      } else {
        this.setState({isLoggedIn: false});
      }

      this.setState({loaded: true});
    })
  }

  hideIfNotLoggedin() {
    if(this.state.isLoggedIn == true) {
      return "";
    } else {
      return "hidden";
    }
  }

  menuRender() {
    if(this.state.userdata.role == "user") {
      return (
        <userMenu />
      );
    } else {
      return (
        <div>
          {this.state.userdata.role}
        </div>
      );
    }
  }

  theSwitch() {
    console.log("SHOULD REDIRECT: " + this.ShouldRedirectLogin)
    if(this.ShouldRedirectLogin()) {
      return (
        <Switch>
          <Route path="/register" component={Register}></Route>
          <Route path="/position/:id" component={Position}></Route>
          <Route path="/recover/:code" component={Recover}></Route>
          <Route path="/forgot" component={Forget}></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/" component={Home}></Route>
        </Switch>
      )
    } else {
      return (
        <Switch>
          <Route path="/login" component={Login}></Route>
          <Route path="/register" component={Register}></Route>
          <Route path="/position/:id" component={Position}></Route>
          <Route path="/profile/:url" exact component={Profile}></Route>
          <Route path="/profile/:url/application/:application" exact component={Profile}></Route>
          <Route path="/application/profile/:application" exact component={AnonProfile}></Route>

          <Route path="/messages/:id" component={Messages}></Route>
          <Route path="/messages" component={Messages}></Route>

          <Route path="/membership" component={Membership}></Route>
          <Route path="/companies" component={Companies}></Route>
          <Route path="/company/:url" component={Company}></Route>

          <Route path="/favorites" component={Favorites}></Route>

          <Route path="/recruiter/create/position" component={RecruiterCreatePosition}></Route>
          <Route path="/recruiter/positions" component={RecruiterPositions}></Route>
          <Route path="/recruiter/position/:id" component={RecruiterPosition}></Route>
          <Route path="/recruiter/search" component={RecruiterSearch}></Route>

          <Route path="/admin/statistic" component={AdminStatistics}></Route>
          <Route path="/admin/users" component={AdminUsers}></Route>


          <Route path="/block/company" component={BlockCompany}></Route>
          <Route path="/recover/:code" component={Recover}></Route>
          <Route path="/:query?" component={Home}></Route>
        </Switch>
      )
    }
  }

  ShouldRedirectLogin() {
    if(this.state.isLoggedIn == false && this.state.loaded == true) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    console.log("Logging out.");
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    window.location = "https://theconnector.no";
  }

  updatePredicate() {
    this.setState({ renderMobileMenu: window.innerWidth < 1000 });

    console.log(window.innerWidth);
  }

  renderMobileMenu() {
    if(this.state.renderMobileMenu == true && this.state.isLoggedIn == true) {
      return (
        <Menu>

          <div><Link to={`/profile/${this.state.userdata.url}`}>Din profil</Link></div>
          <div><Link to={`/favorites`}>Favoritter</Link></div>

          <div className="mobileMenuSpacer"></div>

          <div className={`${this.state.userdata.role == 'user' ? '':'hidden'}`} style={{display: "inline"}}>
            <div><Link to="/">Utlysninger</Link></div>
            <div><Link to="/membership">Medlemsskap</Link></div>
          </div>

          <div className={`${this.state.userdata.role == 'recruiter' ? '':'hidden'}`} style={{display: "inline"}}>
            <div><Link to="/recruiter/positions">Dine utlysninger</Link></div>
          </div>

          <div className={`${this.state.userdata.role == 'recruiter' ? '':'hidden'}`} style={{display: "inline"}}>
            <div><Link to="/recruiter/search">Søk blandt jobbsøkere</Link></div>
          </div>

          <div className={`${this.state.userdata.role == 'admin' ? '':'hidden'}`} style={{display: "inline"}}>
            <div><Link to="/admin/statistic">Statistikk</Link></div>
            <div><Link to="/admin/users">Søk bruker</Link></div>
            <div><Link to="/recruiter/positions">Utlysninger</Link></div>
          </div>

          <div><Link to="/messages">Meldinger</Link></div>

          <div className="mobileMenuSpacer"></div>

          <div><Link onClick={this.logout}>Logg ut</Link></div>
        </Menu>
      );
    } else {
      return;
    }
  }

  render() {
      return (
        <Router>
            {this.renderMobileMenu()}
            <div className={`App ${this.state.loaded == true ? '':'hidden'}`}>
              <div class="container full">
                <div className="navigation">
                  <div className="logo">
                    <Link to="/">
                        <img
                          alt=""
                          src={logo}
                          height="40px"
                          className="d-inline-block align-top"
                        />{' '}
                    </Link>
                  </div>

                  <div className={`menu ${this.ShouldRedirectLogin() ? 'hidden': ''}`}>

                    <div className={`${this.state.userdata.role == 'user' ? '':'hidden'}`} style={{display: "inline"}}>
                      <Link to="/">Utlysninger</Link>
                      <Link to="/membership">Medlemsskap</Link>
                    </div>

                    <div className={`${this.state.userdata.role == 'recruiter' ? '':'hidden'}`} style={{display: "inline"}}>
                      <Link to="/recruiter/positions">Dine utlysninger</Link>
                    </div>

                    <div className={`${this.state.userdata.role == 'recruiter' ? '':'hidden'}`} style={{display: "inline"}}>
                      <Link to="/recruiter/search">Søk blandt jobbsøkere</Link>
                    </div>

                    <div className={`${this.state.userdata.role == 'admin' ? '':'hidden'}`} style={{display: "inline"}}>
                      <Link to="/admin/statistic">Statistikk</Link>
                      <Link to="/admin/users">Søk bruker</Link>
                      <Link to="/recruiter/positions">Utlysninger</Link>


                    </div>

                    <Link to="/messages">Meldinger</Link>

                  </div>

                  <div className={`login profilepicture ${this.ShouldRedirectLogin() ? 'hidden': ''}`} onMouseEnter={(e) => {this.setState({showMenu: true});}} onMouseLeave={(e) => {this.setState({showMenu: false});}}>
                    <Link className="profileLink" to={`/profile/${this.state.userdata.url}`}>{this.state.userdata.firstname} {this.state.userdata.surname}
                      <img style={{height: "40px", width: "40px", borderRadius: "40px", marginRight: "50px", marginLeft: "10px"}} src={`https://l16.vixo.no/api/public/get/image/${this.state.userdata.profilepicture}`} />
                    </Link>
                    <div className={`profileMenuBack ${this.state.showMenu ? '':'hidden'}`}>
                      <div className={`profileMenu`}>
                        <Link to={`/profile/${this.state.userdata.url}`}>Din profil</Link><br />
                        <Link to={`/favorites`}>Favoritter</Link><br />
                        <Link to={`/block/company`}>Blokker firmaer</Link><br />
                        <Link onClick={this.logout}>Logg ut</Link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {this.theSwitch()}

            </div>

            <MessengerCustomerChat
              pageId="951790921576570"
              htmlRef="Aref"
              loggedInGreeting="Hei! Hvordan kan vi hjelpe deg? :)"
              loggedOutGreeting="Hei! Hvordan kan vi hjelpe deg? :)"
              minimized={true}
            />

            <GoogleAnalytics />
        </Router>
      );
  }
}

export default App;
