import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Tab, Tabs, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './position.css';
import swal from 'sweetalert';
import Applicant from '../../../components/applicant/applicant';

export default class RecruiterPosition extends Component<Props> {

  state = {
    showApplication: false,
    job: {

    },


    applications: [],
    tab: "position",
    upsale: false,
    type: "standard",
    upSaleCompany: "",
    upSaleCompanyOrg: "",
    payment: "ehf"

  }

  constructor(props) {
    super(props);

    this.openApplicationForm = this.openApplicationForm.bind(this);
    this.loadApplications = this.loadApplications.bind(this);
    this.loadPosition = this.loadPosition.bind(this);
    this.upsale = this.upsale.bind(this);
  }

  componentDidMount() {
    if(typeof this.props.location.job !== 'undefined') {
      this.setState({job: this.props.location.job});
    }

    this.loadApplications();
    this.loadPosition();
  }

  loadApplications() {
    axios.get("https://l16.vixo.no/api/public/get/recruiter/applications/"+this.props.match.params.id).then(res=>{
      this.setState({applications: res.data})
    });
  }

  loadPosition() {
    axios.get("https://l16.vixo.no/api/public/v2/get/job/" + this.props.match.params.id).then(res => {
      this.setState({job: res.data.data});
    });
  }

  upsale() {
    let payload = "Kontaktperson: \n" + this.state.upSaleCompany + "\n\n Tlf:\n" + this.state.upSaleCompanyOrg + "\n\n Epost:\n" + this.state.payment;

    let data = new FormData();
    data.append("payload", payload);
    data.append("post", this.props.match.params.id);

    axios.post("https://l16.vixo.no/api/public/upsale/flyby", data).then(res => {
      if(res.data.success == true) {
        this.setState({upsale: false});
        swal("Fullført!", "Du vil bli kontaktet for et uforpliktende tilbud!", "success");
      } else {
        swal("Oida!", "Noe gikk galt, prøv igjen!", "error");
      }
    });
  }

  stateChange(e){
    console.log(e);
  }

  openApplicationForm() {
    if(this.state.showApplication == true) {
      this.setState({showApplication: false});
    } else {
      this.setState({showApplication: true});
    }
  }

  deleteApplication() {
    
    swal({
      title: "Er du sikker?",
      text: "Du kan ikke angre denne handlingen!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        let data = new FormData();
        data.append("id", this.props.match.params.id);

        axios.post("https://l16.vixo.no/api/public/v2/delete/position", data).then(res=> {
          if(res.data.status == "success") {
            swal("Fullført!", "Stillingen er nå slettet, og blir fjernet fra søkemotoren innen 10 minutter!", "success");
            this.props.history.push("/recruiter/positions");
          }
        });
      } else {
        swal("Oida!", "Noe gikk galt, prøv igjen!", "error");
      }
    });
  }

  //Removes the anonymous flag on the position
  openApplications() {
    swal({
      title: "Er du sikker på at du ønsker å åpne søknadslisten?",
      text: "Du kan ikke angre denne handlingen! Vennligst merk at åpning av søknadslisten vil sende en melding til alle søkere om at listen er åpnet.",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        let data = new FormData();
        data.append("id", this.props.match.params.id);

        axios.post("https://l16.vixo.no/api/public/v2/open/anon/position", data).then(res=> {
          if(res.data.status == "success") {
            swal("Fullført!", "Søknadslisten for denne stillingen er nå åpen, og alle søkere har mottatt en melding om at listen er åpnet.", "success");
            this.loadApplications();
            this.loadPosition();
          }
        });
      } else {
        swal("Oida!", "Noe gikk galt, prøv igjen!", "error");
      }
    });
  }

  render() {
    const opts = {
      playerVars: {
        modestbranding: 1,
        rel: 0
      }
    };

    return(

      <div>

                <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
                  <Card.Body>
                  <Alert variant="primary" className={`${this.state.job.isAnon == "1" ? '':'hidden'}`}>
                      <Alert.Heading>Anonym søknadsliste</Alert.Heading>
                      <p>
                        Søknadslisten for denne stillingen er anonym fordi det ble valgt under opprettelsen av stillingen.
                      </p>
                      <p className="mb-0">
                      Dersom du velger å åpne listen ved å trykke på "Åpne liste"-knappen nedenfor, vil alle søknader bli synlige med fullt navn og personlig informasjon. Vennligst merk at åpning av søknadslisten vil sende en melding til alle søkere om at listen er åpnet. Vi anbefaler å kun åpne listen når det er nødvendig for å gjennomføre rekrutteringsprosessen
                      </p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button onClick={(e)=>{this.openApplications();}} variant="outline-primary">
                          Åpne liste
                        </Button>
                      </div>
                    </Alert>

                    <div className="positionWrapper">
                      <div className="company">
                      <center><Button variant="danger" onClick={(e)=>{this.deleteApplication();}}>Slett utlysningen</Button></center> <br /><br />
                        <center>
                          <img width="150px" src={`https://l16.vixo.no/api/public/get/image/${this.state.job.logo}`} /><br />
                          <h3 style={{marginTop: "20px"}}>{this.state.job.company}</h3>
                          <h5>{this.state.job.position}</h5>
                        </center>
                        <p>{this.state.job.description}</p>

                        <center><h6 style={{marginTop: "20px"}}>{this.state.job.due}</h6></center>
                        <br /> <br />
                        <h3>Trenger du hjelp?</h3>
                        Få profesjonell hjelp fra The Connector, for å finne de beste kandidatene.<br /><br /> Feilansettelse kan koste mye tid og penger! <br /><br /> Ta kontakt for et uforpliktende tilbud!<br /><br />
                        <Button variant="primary" onClick={(e)=>{this.setState({upsale: true})}}>Bli kontaktet</Button>
                      </div>
                      <div className="position">
                        <div className={`${this.state.showApplication == false ? '': 'hidden'}`}>
                          <h3>{this.state.job.title}</h3>

                          <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.tab}
                            onSelect={(k) => this.setState({tab: k})}
                          >
                            <Tab eventKey="position" title="Utlysning">
                              <br />
                              <p dangerouslySetInnerHTML={{__html: this.state.job.text}}></p>
                              <p dangerouslySetInnerHTML={{__html: this.state.job.requiretext}}></p>
                            </Tab>

                            <Tab eventKey="applications" title={`Søknader (${this.state.applications.length})`}>
                            <br />
                            <ListGroup>

                                   {this.state.applications.map((item) => (
                                        <Applicant isAnon={this.state.job.isAnon} item={item} history={this.props.history} key={item.user.url} />
                                      )
                                    )}

                            </ListGroup>

                            </Tab>

                          </Tabs>

                        </div>

                        <div className={`${this.state.showApplication == false ? 'hidden': ''}`}>
                          <h3>{this.state.job.position} **</h3>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Søknadsbrev</Form.Label>
                              <Form.Control as="textarea" rows="3" placeholder="Beskrivelse" onChange={(e)=>{this.setState({application: e.target.value})}} />
                            </Form.Group>

                            <Button variant="success" size="lg" onClick={this.sendApplication} block>Send!</Button>

                        </div>

                      </div>
                    </div>
                  </Card.Body>
                </Card>



                <Modal show={this.state.upsale} onHide={(e)=>{this.setState({upsale: false})}}>
                  <Modal.Header closeButton>
                    <Modal.Title>Veiledning fra The Connector</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                  <p>Ansettelse er en vanskelig oppgave og feiltrinn kan koste mye tid og penger. </p>
                  <p>Du kan få profesjonell hjelp fra The Connector: med ulike pakker kan du velge hva som passer budsjett og nødvendighet.</p>
                  
                  <b>Tilbud:</b><br />
                  <p>Gjennomlesning av cv’er med objektive vurderinger<br /><i>Kr. 2700,- eks mva</i></p>
                  <p>Bruk av anerkjent Testverktøy for sluttintervjuer, inntil 4 kandidater med gjennomgang.<br /><i>Kr. 4200,- eks mva.</i></p>
                  <p>Lede og strukturere ett intervju via teams.<br /><i>Kr. 3500,- eks mva.</i></p><br />
                  Ved oppmøte for fysisk intervju gjøres avtale ift reise mm.<br /><br />


                  <Form.Group controlId="exampleForm.ControlInput1">
                    <h3>Kontakt Informasjon</h3>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Kontaktperson</Form.Label><br />
                      <Form.Control type="text" placeholder="Ola Nordmann" onChange={(e) => { this.setState({upSaleCompany: e.target.value}) }} />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Telefonnummer <i>(Valgfritt)</i></Form.Label><br />
                      <Form.Control type="text" placeholder="123456789" onChange={(e) => { this.setState({upSaleCompanyOrg: e.target.value}) }} />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Epost</Form.Label><br />
                      <Form.Control type="email" placeholder="ola@nordmann.no" onChange={(e) => { this.setState({payment: e.target.value}) }} />
                    </Form.Group>

                  </Form.Group>

                  <div className={`upsaletext`}>
                    Informasjonen vil kun bli benyttet for å ta kontakt. Vi vil ikke dele informasjonen med tredjepart. Du vil bli kontaktet for et uforpliktende tilbud.
                  </div>

                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={(e)=>{this.setState({upsale: false})}}>
                      Lukk
                    </Button>
                    <Button variant="primary" onClick={this.upsale}>
                      Ta kontakt
                    </Button>
                  </Modal.Footer>
                </Modal>
      </div>
    );
  }
}
