import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {BriefcaseIcon, MortarBoardIcon, AlertIcon, PlusSmallIcon, BookIcon, FileIcon, GlobeIcon, Check, X} from 'react-octicons'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';

//import ProfilePicture from "profile-picture"
//import "profile-picture/build/ProfilePicture.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faCheck, faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import "video-react/dist/video-react.css";
import { Player } from 'video-react';

import swal from 'sweetalert';

import './profile.css';

import defaultVideo from '../../images/theconnector.mp4';

import DatePicker from 'react-date-picker';

export default class Profile extends Component<Props> {
  state = {
    showAddTimeline: false,
    showEditProfile: false,
    profile: {
      references: [],
      experience: []
    },
    additional: {
      role: ''
    },
    showApplication: false,
    application: {
      data: {
        application: ""
      }
    },
    yourProfile: false,
    editProfilePicture: false,

    showRejectionForm: false,
    letter: "",

    video: {
      name: ""
    },

    videoUpload: 0,

    location: "",

    categories: [],
    chosenCategory: 0,

    subCategories: [],
    chosenSubCategory: 0,

    experience: "",
    experienceType: "work",
    experienceDescription: "",
    experienceMisc: "grunnskole",
    date1: "",
    date2: "",
    isEditing: false,
    tips: {
      missing_experience: false,
      missing_profile_picture: false,
      missing_video: false
    },
    showtips: false

  }

  constructor(props) {
    super(props);

    this.getProfile = this.getProfile.bind(this);
    this.toggleTimelineAdd = this.toggleTimelineAdd.bind(this);
    this.toggleEditProfile = this.toggleEditProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.sendRejection = this.sendRejection.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
    this.toggleProfilePiture = this.toggleProfilePiture.bind(this);
    this.handleProfilePictureUpload = this.handleProfilePictureUpload.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
    this.locationPicked = this.locationPicked.bind(this);
    this.addToTimeline = this.addToTimeline.bind(this);
    this.deleteFromTimeline = this.deleteFromTimeline.bind(this);
    this.editTimeline = this.editTimeline.bind(this);
    this.deleteProfile = this.deleteProfile.bind(this);
    this.loadCategories = this.loadCategories.bind(this);
    this.loadSubCategories = this.loadSubCategories.bind(this);

    this.profilePictureRef = React.createRef();
  }

  componentDidMount() {
    this.getProfile();
    this.loadCategories();
    console.log("Load");

    if(typeof this.props.match.params.application !== 'undefined') {
      this.setState({showApplication: true});
      this.getApplication();
    }
  }

  loadCategories() {
    axios.get("https://l16.vixo.no/api/public/v2/get/categories").then(res => {
      this.setState({categories: res.data});
    })
  }

  loadSubCategories(e) {
    axios.get("https://l16.vixo.no/api/public/v2/get/subcategories?id="+e).then(res => {
      this.setState({subCategories: res.data});
    })
  }

  getProfile() {
    axios.get("https://l16.vixo.no/api/public/get/Profile/user/"+this.props.match.params.url).then(res => {
      this.setState({profile: res.data});

      let viewerData = JSON.parse(window.localStorage.getItem("userData"));
      if(viewerData !== null) {
        if(typeof viewerData.id !== 'undefined') {
          if(this.state.profile.id == viewerData.id) {
            this.setState({yourProfile: true});
            this.getTips();
          }
        }
      }
    });

    axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
      this.setState({additional: res.data});
    }).catch(function (error) {
      //swal("Oida!", "Vi kunne ikke laste inn profilen, prøv igjen senere!", "error");
    });
  }


  getTips() {
    axios.get("https://l16.vixo.no/api/public/get/tips").then(res => {
      console.log(res.data);
      this.setState({tips: res.data});
    });
  }

  getApplication() {
    axios.get("https://l16.vixo.no/api/public/v2/get/application/"+this.props.match.params.application).then(res => {
      this.setState({application: res.data});
    });
  }

  toggleTimelineAdd() {
    console.log(this.state.showAddTimeline);
    if(this.state.showAddTimeline == true) {
      this.setState({showAddTimeline: false});
    } else {
      this.setState({showAddTimeline: true});
    }
  }

  toggleEditProfile() {
    console.log(this.state.showEditProfile);
    if(this.state.showEditProfile == "edit") {
      this.setState({showEditProfile: false});
    } else {
      this.setState({showEditProfile: "edit"});

      let userData = JSON.parse(localStorage.getItem("userData"));

      this.setState({firstname: this.state.profile.firstname});
      this.setState({surname: this.state.profile.surname});
      this.setState({biography: this.state.profile.biography});
      this.setState({phone: this.state.additional.phone});
      this.setState({gender: this.state.additional.gender});
      this.setState({chosenCategory: this.state.additional.cat1});
      this.setState({chosenSubCategory: this.state.additional.cat2});
      this.loadSubCategories(this.state.additional.cat1);
    }
  }

  toggleProfilePiture() {
    if(this.state.showEditProfile == "profilepicture") {
      this.setState({showEditProfile: false});
    } else {
      this.setState({showEditProfile: "profilepicture"});
    }
  }

  updateProfile() {
    let data = new FormData();
    data.append("firstname", this.state.firstname);
    data.append("surname", this.state.surname);
    data.append("biography", this.state.biography);
    data.append("phone", this.state.phone);
    data.append("gender", this.state.gender);
    data.append("location", this.state.location);

    data.append("cat1", this.state.chosenCategory);
    data.append("cat2", this.state.chosenSubCategory);

    axios.post('https://l16.vixo.no/api/public/v2/update/user', data).then(res => {
      if(res.data.success == "true") {
        this.getProfile();
        this.toggleEditProfile();
        swal("Oppdatert!", "Din profil er nå oppdatert!", "success");
      }
    });
  }

  sendRejection() {
    let data = new FormData();
    data.append("application", this.props.match.params.application);
    data.append("message", this.state.letter);

    axios.post('https://l16.vixo.no/api/public/v2/application/decline', data).then(res => {
      if(res.data.success == true) {
        swal("Søknaden er nå avslått!", "Søknaden er nå markert som avslått, og melding har blitt sendt til søkeren!", "success");
        this.props.history.push("/recruiter/positions");
      } else {
        swal("oida!", "Vi kunne ikke avslå søknaden akuratt nå, prøv igjen senere!", "error");
      }
    });
  }

  toggleFavorite() {
    let data = new FormData();

    data.append("application", this.props.match.params.application);

    if(this.state.application.data.favorite == true) {
      data.append("favorite", 0);
    } else {
      data.append("favorite", 1);
    }

    axios.post("https://l16.vixo.no/api/public/set/favorite/application", data).then(res => {
      this.getApplication();
    });
  }

  srcToFile(src, fileName, mimeType){
    return (fetch(src)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], fileName, {type:mimeType});})
    );
  }

  handleProfilePictureUpload() {
    const PP = this.profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    this.srcToFile(imageAsDataURL, "profile.png", "image/png").then(img => {
      const data = new FormData();
      data.append('file', img);
      data.append('name', "profilepicture.png");
      axios.post("https://l16.vixo.no/api/public/upload/profilepicture", data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then(res => {
        console.log(JSON.stringify(res))
        if(res.status = 200) {
          swal("Fullført!", "Profilbildet er nå lastet opp!", "success");
          this.setState({showEditProfile: false});
          this.getProfile();

          let event = new Event('loadUserData');
          window.dispatchEvent(event);
        } else {
          swal("Oida!", "Vi kunne ikke laste opp ditt nye profilbilde, prøv igjen senere.", "error");
        }
      });
    })
  }

  chooseVideo(video) {
    this.setState({video: video[0]});
  }

  uploadVideo() {
    const data = new FormData();
    data.append('file', this.state.video);
    data.append('name', "video.mp4");
    axios.post("https://l16.vixo.no/api/public/upload/video", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ProgressEvent => {
         this.setState({
           videoUpload: (ProgressEvent.loaded / ProgressEvent.total*100),
       })
      }
    }).then(res => {
      console.log(JSON.stringify(res))
      if(res.status = 200) {
        swal("Fullført!", "Video er nå lastet opp!", "success");
        this.setState({showEditProfile: false});
      } else {
        swal("Oida!", "Vi kunne ikke laste opp din nye video, prøv igjen senere.", "error");
      }
    });
  }

  locationPicked(e) {
    this.setState({location: e.description});
  }


  addToTimeline() {

    let date1 = new Date(this.state.date1);
    date1 = JSON.stringify(date1)
    date1 = date1.slice(1,11)

    let date2 = new Date(this.state.date2);
    date2 = JSON.stringify(date2)
    date2 = date2.slice(1,11)

    let payload = new FormData();
    payload.append("type", this.state.experienceType);
    payload.append("title", this.state.experience);
    payload.append("text", this.state.experienceDescription);
    payload.append("misc", this.state.experienceMisc);
    payload.append("from", date1);
    payload.append("to", date2);

    axios.post("https://l16.vixo.no/api/public/addexperience", payload).then(res => {
      if(res.data.success) {
        swal("Fullført!", "Du har nå lagt til en hendelse på din tidslinje!", "success");

        this.setState({experienceType: "work"});
        this.setState({experience: ""});
        this.setState({experienceDescription: ""});
        this.setState({date1: ""});
        this.setState({date2: ""});

        this.getProfile();
      } else {
        swal("Oida!", "Vi kunne ikke legge til hendelsen på din tidslinje! Har du fylt ut alle feltene?", "error");
      }
    });
  }

  deleteFromTimeline(item) {
    swal("Er du sikker på at du vil slette denne hendelsen?", {
      buttons: {
        cancel: "Avbryt",
        catch: {
          text: "Slett",
          value: "delete",
        }
      },
    })
    .then((value) => {
      if(value == "delete") {
        swal("Fullført!", "Hendelsen er nå slettet fra din tidslinje!", "success");

        console.log(item);

        let payload = new FormData();
        payload.append("id", item.id);

        axios.post("https://l16.vixo.no/api/public/v2/experience/delete", payload).then(res => {
          if(res.data.success) {
            swal("Fullført!", "Du har nå slettet en hendelse på din tidslinje!", "success");
            this.getProfile();
          } else {
            swal("Oida!", "Vi kunne ikke slette hendelsen på din tidslinje! Prøv igjen senere!", "error");
          }
        });
      }
    });
  }

  editTimeline(item) {
    this.setState({experienceDescription: item.text});
    this.setState({experience: item.title});
    this.setState({experienceType: item.type});
    this.setState({date1: item.start});
    this.setState({date2: item.finish});

  }


  deleteProfile() {
    let data = new FormData();
    data.append("user", this.state.profile.id);

    axios.post('https://l16.vixo.no/api/public/v2/user/delete', data).then(res => {
      if(res.data.success == true) {
        swal("Brukeren er nå slettet!", "Brukeren er nå slettet fra portalen!", "success");
        this.props.history.push("/");
      } else {
        swal("oida!", "Vi kunne ikke slette brukeren akuratt nå, prøv igjen senere!", "error");
      }
    });
  }

  adminSection() {
    if(this.state.additional.role == "admin") {
      return (
        <div>
          <br />
          <br />
          <br />
          <h4>Admin informasjon</h4>
          <b>Telefon:</b><br />
          {this.state.profile.phone}<br />
          <b>Epost:</b><br />
          {this.state.profile.email}<br />
          <b>Registrert:</b><br />
          {this.state.profile.registered}<br /><br/>

          <button className="btn btn-warning">Rediger konto</button><br />
          <button className="btn btn-danger" onClick={this.deleteProfile}>Slett konto</button>
        </div>
      )
    }
  }

  companySection() {
    if(this.state.profile.role == "company") {
      return (
        <div>
          <br />
          <br />
          <h4>{this.state.profile.company.name}</h4>
          <b>Org:</b><br />
          {this.state.profile.company.org}<br /><br/>
        </div>
      )
    }
  }

  render() {
    const autocompletionRequest = {
      componentRestrictions: {
        country: "NO"
      }
    }
    return(
      <div>

                <Card className="tcContainer">
                  <Card.Body>
                    <div className={`applicationLetter ${this.state.showApplication == false ? 'hidden':''}`}>
                      <div className={`applicationLetter ${this.state.showApplication == false ? 'hidden':''}`} style={{float: "right"}}>
                        <Button className={`${this.state.application.data.favorite == false ? '':'hidden'}`} variant="primary" onClick={this.toggleFavorite} style={{marginRight: "10px"}}>Sett som favoritt</Button>
                        <Button className={`${this.state.application.data.favorite == false ? 'hidden':''}`} variant="danger" onClick={this.toggleFavorite} style={{marginRight: "10px"}}>Fjern som favoritt</Button>

                        <Button variant="danger" onClick={(e) => {
                          this.setState({showRejectionForm: true});
                        }}>Avslå søknad</Button>
                      </div>

                      <br style={{clear:'both'}} />
                      <br style={{clear:'both'}} />
                    </div>

                    <div className="positionWrapper">
                      <div className="profile">
                        <div className={`${this.state.yourProfile == true ? '' : 'hidden'} ProfileSettings`}>
                          <div className={`editProfile`} onClick={this.toggleProfilePiture}>
                          Profilbilde <FontAwesomeIcon icon={faCog} />
                          </div>

                          <div className={`editProfile`} onClick={this.toggleEditProfile}>
                          Rediger <FontAwesomeIcon icon={faCog} />
                          </div>

                          <div className={`${this.state.yourProfile == true ? '' : 'hidden'} tipsProfile`} onClick={(e) => {this.setState({showTips: true})}}>
                            Tips <FontAwesomeIcon icon={faQuestionCircle} />
                          </div>
                        </div>

                        <div>
                          <center>
                            <img width="150px" style={{borderRadius: "75px"}} onClick={this.toggleProfilePiture} src={`https://l16.vixo.no/api/public/get/image/${this.state.profile.profilepicture}`} />
                            <div className={`${this.state.yourProfile == false ? '' : 'hidden'}`}>
                              <br />
                              <Button variant="primary" onClick={(e) => {this.props.history.push("/messages/"+this.state.profile.id)}} style={{marginTop: "20px"}}>Send melding</Button>
                            </div>

                            <h3 style={{marginTop: "20px"}}>{this.state.profile.firstname} {this.state.profile.surname}</h3>
                          </center>
                          <p>{this.state.profile.biography}</p>

                          <div className="printCV">
                            <a href={`https://l16.vixo.no/api/public/get/printablecv/${this.props.match.params.url}`} target="_blank">Last ned / print CV</a>
                          </div>

                          {this.companySection()}

                          {this.adminSection()}

                          <div className={this.state.yourProfile ? '':'hidden'}>
                            <div className={this.state.yourProfile ? '':'hidden'}>
                              <h3>Tips</h3>
                              <span className={`check ${this.state.tips.missing_profile_picture ? 'hidden':''}`}><FontAwesomeIcon icon={faCheck} /></span><span className={`cross ${this.state.tips.missing_profile_picture ? '':'hidden'}`}><FontAwesomeIcon icon={faTimes} /></span> Lastet opp profilbilde.<br />
                              <span className={`check ${this.state.tips.missing_experience ? 'hidden':''}`}><FontAwesomeIcon icon={faCheck} /></span><span className={`cross ${this.state.tips.missing_experience ? '':'hidden'}`}><FontAwesomeIcon icon={faTimes} /></span> Lagt inn hendelser på tidslinjen / CV.<br />
                              <span className={`check ${this.state.tips.missing_video ? 'hidden':''}`}><FontAwesomeIcon icon={faCheck} /></span><span className={`cross ${this.state.tips.missing_video ? '':'hidden'}`}><FontAwesomeIcon icon={faTimes} /></span> Lastet opp video.<br />
                            </div>

                          </div>
                        </div>

                      </div>
                      <div className="position">
                        <div className={`profile ${this.state.showEditProfile == "profilepicture" ? '':'hidden'}`}>

                          <h3 style={{marginTop: "20px"}}>Profilbilde</h3>
                            {/*<ProfilePicture
                              ref={this.profilePictureRef}
                              useHelper={true}
                              frameFormat="circle"
                              debug={true}
                            />*/}
                            <p>Vi gjør oppmerksom på grunn av tekniske oppdateringer vil det midlertidig ikke være mulig å endre profilbildet ditt. Vi beklager eventuelle ulemper dette kan medføre, og vi jobber hardt for å få denne funksjonen tilbake på plass så raskt som mulig. Inntil videre vil det profilbildet du allerede har lastet opp fortsatt være synlig på profilen din.</p>

                          {/*<Button variant="primary" onClick={this.handleProfilePictureUpload}>Last opp</Button>*/}

                        </div>

                        <div className={`profile ${this.state.showEditProfile == "edit" ? '':'hidden'}`}>
                          <h3>Informasjon</h3>
                          <div className="half">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Fornavn</Form.Label>
                              <Form.Control type="text" placeholder="Olas bakeri" value={this.state.firstname} onChange={(e) => {this.setState({firstname: e.target.value})}} />
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Etternavn</Form.Label>
                              <Form.Control type="text" placeholder="Olas bakeri" value={this.state.surname} onChange={(e) => {this.setState({surname: e.target.value})}} />
                            </Form.Group>
                          </div>

                          <div className="half">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Adresse</Form.Label>
                              <GooglePlacesAutocomplete onSelect={(e) => {this.locationPicked(e)}} autocomplete="off" placeholder={this.state.additional.location == "" ? "Norgesveien 4, 1234 Oslo" : this.state.additional.location} autocompletionRequest={autocompletionRequest} />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Kjønn</Form.Label>
                              <Form.Control as="select" value={this.state.gender} onChange={(e) => { this.setState({gender: e.target.value}) }}>
                                <option value="male">Mann</option>
                                <option value="female">Kvinne</option>
                                <option value="other">Annet</option>
                                <option value="">Ønsker ikke å spesifisere</option>
                              </Form.Control>
                            </Form.Group>
                          </div>

                          <div className="half">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Epost</Form.Label>
                            <Form.Control type="text" placeholder="ola@example.org" value={this.state.profile.email} disabled />
                            <Form.Text className="text-muted">
                              <i>Kontakt support for endringer.</i>
                            </Form.Text>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control type="text" placeholder="12345678" value={this.state.phone} onChange={(e) => {this.setState({phone: e.target.value})}} />
                          </Form.Group>
                          </div>

                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Biografi</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Beskrivelse" value={this.state.biography} onChange={(e) => {this.setState({biography: e.target.value})}} />
                          </Form.Group>

                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Sektor</Form.Label>
                            <Form.Control as="select" value={this.state.chosenCategory} onChange={(e) => { this.setState({chosenCategory: e.target.value}); this.loadSubCategories(e.target.value); this.setState({chosenSubCategory: 0}); }}>
                              <option value="0">Uspesifisert</option>
                              {this.state.categories.map((category) => (
                                <option value={category.id}>{category.name}</option>
                              ))}
                            </Form.Control>

                          </Form.Group>


                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Undersektor</Form.Label>
                            <Form.Control as="select" value={this.state.chosenSubCategory} onChange={(e) => { this.setState({chosenSubCategory: e.target.value}) }}>
                              <option value="0">Uspesifisert</option>
                              {this.state.subCategories.map((category) => (
                                <option value={category.id}>{category.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>

                          <Button variant="primary" onClick={this.updateProfile}>Lagre</Button>

                          <h3 style={{marginTop: "20px"}}>Introduksjons video</h3>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Velg video filen</Form.Label>
                            <div className="custom-file">
                              <input type="file" className="custom-file-input" id="customFileLang" lang="no" onChange={ (e) => this.chooseVideo(e.target.files) } />
                              <label className="custom-file-label" for="customFileLang">{this.state.video.name == "" ? 'velg' : this.state.video.name}</label>
                            </div>
                          </Form.Group>

                          <ProgressBar variant="success" now={this.state.videoUpload} label={`${Math.floor(this.state.videoUpload)}%`} className={`${this.state.videoUpload != 0 ? '':'hidden'}`} />
                          <br />

                          <Button variant="primary" onClick={this.uploadVideo}>Last opp</Button>

                        </div>


                        <div className={`profile ${this.state.showEditProfile == false ? '':'hidden'}`}>
                          <div className={`applicationLetter ${this.state.showApplication == false ? 'hidden':''}`}>
                            <div className="titleArea" >
                              <h3 className="title">Søknadsbrev</h3>
                            </div>
                            <p dangerouslySetInnerHTML={{__html: this.state.application.data.application}}></p>
                          </div>

                          <div className={`${this.state.profile.video == false ? 'hidden':''}`}>
                              <Player
                                playsInline
                                src={`https://l16.vixo.no/api/public/get/video/${this.state.profile.video}`}
                              />
                            <br />
                          </div>

                          <div className={`${this.state.profile.video == false && this.state.yourProfile == true ? '':'hidden'}`}>
                              <Player
                                playsInline
                                src={defaultVideo}
                              />
                            <br />
                          </div>

                          <div className="titleArea">
                            <h3 className="title">CV-tidslinje</h3>

                            <h3 className={`actions animate__animated animate__pulse animate__infinite animate__slower ${this.state.yourProfile == true ? '' : 'hidden'}`}><span className="addWrapper" onClick={this.toggleTimelineAdd}><span className="addText">Legg til</span> <PlusSmallIcon height="40px" /></span></h3>
                          </div>

                          <div className={`addTimeline ${ this.state.showAddTimeline == true ? '' : 'hidden' }`}>

                            <div className={this.state.isEditing == true ? '':'hidden'}>
                              <h3>Redigerer {this.state.experience}</h3>
                            </div>


                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Tittel / Navn</Form.Label>
                              <Form.Control type="text" placeholder="Olas bakeri" value={this.state.experience} onChange={(e) => {this.setState({experience: e.target.value})}} />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Beskrivelse</Form.Label>
                              <Form.Control as="textarea" rows="3" placeholder="Beskrivelse" value={this.state.experienceDescription} onChange={(e) => {this.setState({experienceDescription: e.target.value})}} />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Type</Form.Label>
                              <Form.Control as="select" value={this.state.experienceType} onChange={(e) => {this.setState({experienceType: e.target.value})}}>
                                <option value="work">Arbeidsplass</option>
                                <option value="education">Utdanning</option>
                                <option value="project">Prosjekt</option>
                                <option value="charity">Frivillig / Veldedighet</option>
                                <option value="certification">Sertifisering</option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1" className={`${this.state.experienceType == 'education' ? '':'hidden'}`}>
                              <Form.Label>Type</Form.Label>
                              <Form.Control as="select" value={this.state.experienceMisc} onChange={(e) => {this.setState({experienceMisc: e.target.value})}}>
                                <option value="grunnskole">Grunnskole</option>
                                <option value="videregaaende">Videregående</option>
                                <option value="fagskole">Fagskole</option>
                                <option value="1aarhoyskole">1 år høyskole</option>
                                <option value="23aarhoyskole">2-3 år høyskole</option>
                                <option value="hoyskoleuten">Høyskole uten grad</option>
                                <option value="bachelor">Bachelorgrad</option>
                                <option value="master">Mastergrad</option>
                                <option value="doctor">Doktorgrad</option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Fra</Form.Label><br />
                              <DatePicker onChange={(e) => {this.setState({date1: e})}} value={this.state.date1} />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Til</Form.Label><br />
                              <DatePicker onChange={(e) => {this.setState({date2: e})}} value={this.state.date2} />
                              <br /><i>Hvis du ikke er ferdig behold denne blank.</i>
                            </Form.Group>

                            <Button variant="success" size="lg" block onClick={this.addToTimeline}>Legg til!</Button>

                          </div>

                          <VerticalTimeline layout="1-column">

                            {this.state.profile.experience.map(function(item, i){
                              let icon = (<BriefcaseIcon fill="white" />);
                              let color = "";
                              let text = "";

                              let misc = " - ";
                              switch(item.misc) {
                                case "grunnskole":
                                  misc += "Grunnskole";
                                  break;
                                case "videregaaende":
                                  misc += "Videregående";
                                  break;
                                case "fagskole":
                                  misc += "Fagskole";
                                  break;
                                case "1aarhoyskole":
                                  misc += "1 år høyskole";
                                  break;
                                case "23aarhoyskole":
                                  misc += "2 - 3 år høyskole";
                                  break;
                                case "hoyskoleuten":
                                  misc += "Høyskole uten grad";
                                  break;
                                case "bachelor":
                                  misc += "Bachelorgrad";
                                  break;
                                case "master":
                                  misc += "Mastergrad";
                                  break;
                                case "doctor":
                                  misc += "Doktorgrad";
                                  break;
                                default:
                                  // code block
                                  misc = "";
                              }

                              switch(item.type) {
                                case "work":
                                  icon = (<BriefcaseIcon fill="white" />);
                                  color = "rgb(33, 150, 243)";
                                  text = "Arbeidsplass";
                                  break;
                                case "education":
                                  icon = (<MortarBoardIcon fill="white" />);
                                  color = "#449e43";
                                  text = "Utdanning" + misc;
                                  break;
                                case "course":
                                  icon = (<BookIcon fill="white" />);
                                  color = "#449e43";
                                  text = "Kurs";
                                  break;
                                case "project":
                                  icon = (<FileIcon fill="white" />);
                                  color = "rgb(33, 150, 243)";
                                  text = "Prosjekt";
                                  break;
                                case "charity":
                                  icon = (<GlobeIcon fill="white" />);
                                  color = "rgb(114, 116, 181)";
                                  text = "Veldedighet";
                                  break;
                                case "void":
                                  icon = (<AlertIcon fill="white" />);
                                  color = "#d26a5c";
                                  break;
                                default:
                                  // code block
                              }


                              return (
                                <VerticalTimelineElement
                                  className="vertical-timeline-element--work"
                                  contentStyle={{ background: '#f0f0f0', color: 'black' }}
                                  contentArrowStyle={{ borderRight: '7px solid  #f0f0f0' }}
                                  date=""
                                  iconStyle={{ background: color, color: 'white' }}
                                  icon={icon}
                                >
                                  <h4 className="vertical-timeline-element-title">{item.title}</h4>
                                  <h6 className="vertical-timeline-element-subtitle">{item.start} -  {item.finish == '1970-01-01' ? 'Nåværende' : item.finish}</h6>
                                  <i>{text}</i>
                                  <p>
                                    {item.text}
                                  </p>
                                  <div className={`${this.state.yourProfile && item.type != "void" ? '':'hidden'} editTimeline`}>
                                    <div></div>
                                    <div className="action" onClick={(e) => {this.deleteFromTimeline(item)}}>Slett</div>
                                    <div className="action" onClick={(e) => {this.editTimeline(item)}}>Rediger</div>
                                  </div>
                                </VerticalTimelineElement>

                              )
                            }, this)}
                          </VerticalTimeline>

                          <div className={`references ${this.state.profile.references.length == 0 && this.state.yourProfile == false ? 'hidden' : ''}`}>
                            <h3>Referanser</h3>
                            <i>Kommer snart!</i>
                          </div>

                          {this.state.profile.references.map(function(item, i){
                            return (
                              <div style={{paddingLeft: "10px"}}>
                                <h4>{item.company}</h4>
                                <div style={{paddingLeft: "10px"}}>
                                  <p>
                                    <b>Ansvar:</b><br />
                                    {item.responsibilities}
                                  </p>
                                  <p>
                                    <b>Utførelse:</b><br />
                                    {item.execution}
                                  </p>
                                  <p>
                                    <b>Samarbeid:</b><br />
                                    {item.coop}
                                  </p>
                                  <p>
                                    <b>Positive egenskaper:</b><br />
                                    {item.positive}
                                  </p>
                                </div>
                                <p>
                                  <i>
                                    <span className={item.leader == "yes" ? '':'hidden'}>Dette er en lederstilling.</span>
                                    <span className={item.employeagain == "yes" ? '':'hidden'}> Arbeidsgiver hadde ansatt denne arbeidstakeren igjen.</span>
                                  </i>
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>


                <Modal show={this.state.editProfilePicture} onHide={(e) => {this.setState({editProfilePicture: false})}}>
                  <Modal.Header closeButton>
                    <Modal.Title>Velg profilbilde</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {/*<ProfilePicture
                      ref={this.profilePictureRef}
                      useHelper={true}
                      debug={true}
                        />*/}

                            <p>På grunn av tekniske oppgraderinger kan du ikke oppdatere profilbildet akuratt nå.</p>
                  </Modal.Body>
                </Modal>

                <Modal show={this.state.showRejectionForm} onHide={(e) => {this.setState({showRejectionForm: false})}}>
                  <Modal.Header closeButton>
                    <Modal.Title>Avslå søknad</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Skriv en melding til søker:<br />
                    <CKEditor
                      editor={ ClassicEditor }
                      data=""
                      onInit={ editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          //console.log( { event, editor, data } );
                          this.setState({letter: data})
                      } }
                    />
                    <div>
                      <Button variant="danger" style={{float: "right", marginTop: "10px"}} onClick={this.sendRejection}>Send avslag</Button>
                    </div>
                  </Modal.Body>
                </Modal>


                <Modal show={this.state.showTips} onHide={(e) => {this.setState({showTips: false})}}>
                  <Modal.Header closeButton>
                    <Modal.Title>Tips</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <h6>For at dere skal bli attraktive for bedrifter og rekrutteringsselskaper, har vi følgende tips som vi vet fungerer:</h6>
                  <p>Du skal selge deg inn – ikke spar på noe, du konkurrerer med mange andre som har tilsvarende jobbdrømmer!</p>
                  <ul>
                    <li>Last opp et representativt bilde av deg selv. Husk: de vil seg deg uansett i et intervju.</li>
                    <li>Last opp en liten presentasjonsvideo med et kort innhold av hvem du er og din motivasjon.</li>
                  </ul>
                  <h6>Husk: De vil høre deg prate i et intervju – nå kan du selge deg inn til intervjuet!</h6>
                  <ul>
                    <li>Skriv kort om deg selv som legges inn under bildet.</li>
                    <li>Last opp alle utdanninger/vitnemål, kurs, attester/jobbreferanser.</li>
                  </ul>
                  <p>En solid og fyldig profil sier noe om din motivasjon, gjennomføringsevne, flid – du vil skille deg ut positivt og bli lagt merke til.</p>
                  </Modal.Body>
                </Modal>
      </div>
    );
  }
}
