import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Table } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faSpinner } from '@fortawesome/free-solid-svg-icons'

import {BriefcaseIcon} from 'react-octicons'

import axios from "axios";

import Chart from "react-google-charts";

import './users.css';

export default class AdminUsers extends Component<Props> {
  state = {
    isLoggedIn: false,
    search: "",
    result: [],
    filter: 'user',
    order: 'none'
  }

  constructor(props) {
    super(props);

    let event = new Event('loadUserData');
    window.addEventListener('loadUserData', this.loadUserData, false);
    this.search = this.search.bind(this);
    this.exportUsers = this.exportUsers.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
  }


  search(query = this.state.search, filter = this.state.filter, order = this.state.order) {
    this.setState({search: query, filter: filter, order: order});

    let data = new FormData();
    data.append("query", query);
    data.append("filter", filter);
    data.append("order", order);

    axios.post("https://l16.vixo.no/api/public/v2/search/users", data).then(res => {
      this.setState({result: res.data.list});
    })
  }

  openProfile(token) {
    //this.props.history.push({
    //  pathname: "/profile/"+token,
    //})
    window.open("/profile/"+token, "_blank");
  }

  exportUsers() {
    axios({
      url: 'https://l16.vixo.no/api/public/export/users', //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'users.xls'); //or any other extension
       document.body.appendChild(link);
       link.click();
    });
  }




  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>
          <Card className="tcContainer">
            <Card.Body>
              <Card.Title>Søk brukere</Card.Title>
              <Card.Text>
              <div className={`searchWrapper ${this.state.searched == true && (this.state.profileStatus.missing_experience == true || this.state.profileStatus.missing_profile_picture == true) ? 'hidden':''}`}>

                <div className="searchContainer">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Control type="text" placeholder="F.eks Kåre Hansen" value={this.state.search} onChange={(e) => {this.setState({search: e.target.value})}} />
                  </Form.Group>
                </div>

                <div className="searchBtn">
                  <Button variant="primary" onClick={() => {this.search()}}>
                    Søk
                  </Button><br />
                </div>
              </div>

              <div className="optionsWrapper">
                <div className="export">
                  <Button variant="primary" onClick={this.exportUsers}>
                    Eksporter
                  </Button>
                </div>

                <div className="userFilter">
                  <Form.Control as="select" value={this.state.filter} onChange={(e) => {this.search(this.state.search, e.target.value, this.state.order);}}>
                    <option value="user">Bruker</option>
                    <option value="recruiter">Hodejeger</option>
                    <option value="company">Firma</option>
                    <option value="admin">Administrator</option>
                  </Form.Control>
                </div>

                <div className="orderFilter">
                  <Form.Control as="select" value={this.state.order} onChange={(e) => {this.search(this.state.search, this.state.filter, e.target.value);}}>
                    <option value="none">Ingen</option>
                    <option value="latest">Nyligste</option>
                    <option value="oldest">Eldste</option>
                  </Form.Control>
                </div>
              </div>

               <Table striped bordered hover>
                 <thead>
                   <tr>
                     <th>Profilbilde</th>
                     <th>Navn</th>
                     <th className={`${this.state.filter == 'company' ? 'hidden':''}`}>Biografi</th>
                     <th className={`${this.state.filter == 'company' ? '':'hidden'}`}>Selskap</th>
                     <th className={`${this.state.filter != 'user' ? '':'hidden'}`}>Utlysninger</th>
                   </tr>
                 </thead>
                 <tbody>

                   {this.state.result.map((item) => (
                          <tr onClick={(e) => {this.openProfile(item.url)}}>
                            <td><img src={`https://l16.vixo.no/api/public/get/image/${item.profilepicture}`} height="40px" /></td>
                            <td>{item.name}</td>
                            <td className={`${this.state.filter == 'company' ? 'hidden':''}`}>{item.biography}</td>
                            <td className={`${this.state.filter == 'company' ? '':'hidden'}`}>{item.company}</td>
                            <td className={`${this.state.filter != 'user' ? '':'hidden'}`}>{item.posts}</td>
                          </tr>
                      )
                    )}

                 </tbody>
               </Table>

            </Card.Text>
          </Card.Body>
        </Card>
        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
