import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './forgot.css';
import swal from 'sweetalert';

export default class Recover extends Component<Props> {

  state = {
    password: "",
    password2: "",
    success: false,
    msg: ""
  }

  constructor(props) {
    super(props);

    this.forgot = this.forgot.bind(this);
  }

  componentDidMount() {
  }

  forgot() {
    let data = new FormData();
    data.append("password", this.state.password);
    data.append("code", this.props.match.params.code);

    axios.post('https://l16.vixo.no/api/public/recover/update', data).then(res => {
      this.setState({success: res.data.success});
      if(res.data.success) {
        this.setState({password: ""});
        this.setState({password2: ""});
        //this.props.history.push("/login");
        swal({
          title: "Fullført!",
          text: "Passordet er nå oppdatert, du kan logge på via appen.",
          icon: "success",
          confirmButtonText: "OK"
        });
      } else {
        swal({
          title: "Feil",
          text: "Koden har utløpt, eller passordene er ikke like.",
          icon: "error",
          confirmButtonText: "OK"
        });
      }
    })
  }

  stateChange(e){
    console.log(e);
  }

  render() {
    return(
      <div className="login-wrapper recover">


              <h1>Nytt passord</h1>

              <Form.Group controlId="formBasicEmail">
                <Form.Control type="password" placeholder="Nytt passord" value={this.state.password} onChange={(e) => {this.setState({password: e.target.value})}} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control type="password" placeholder="Gjenta nytt passord" value={this.state.password2} onChange={(e) => {this.setState({password2: e.target.value})}} />
              </Form.Group>

              <button type="submit" onClick={this.forgot} className="loginBtn">
                Lagre
              </button><br />
      </div>
    );
  }
}
