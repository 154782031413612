import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faSpinner } from '@fortawesome/free-solid-svg-icons'


import DatePicker from 'react-date-picker';

import axios from "axios";

import swal from 'sweetalert';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {BriefcaseIcon, MortarBoardIcon, AlertIcon, PlusSmallIcon, BookIcon, FileIcon, GlobeIcon, Check, X} from 'react-octicons'

export default class Applicant extends Component<Props> {
  state = {
    job: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  handleClick() {
    if(this.props.item.isAnon == "1") {
      this.props.history.push("/application/profile/"+this.props.item.id);
    } else {
      this.props.history.push("/profile/"+this.props.item.user.url+"/application/"+this.props.item.id);
    }
  }

  render() {
    return(
        <ListGroup.Item className="listGroupSegments" onClick={(e) => {this.handleClick()}}>
            <div className="lgLogo">
                <img style={{height: "70px", width: "70px", borderRadius: "40px", marginRight: "50px", marginLeft: "10px"}} src={`https://l16.vixo.no/api/public/get/image/${this.props.item.user.profilepicture}`} />
            </div>
            <div className="lgContent">
                <h4>{this.props.item.user.name}</h4>
                <p>&nbsp;</p>
            </div>

            <div className={`lgAction ${this.props.item.favorite == "1" ? '' : 'hidden'}`}>
                Favoritt
            </div>

        </ListGroup.Item>   
    )
  }
}
