import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faSpinner } from '@fortawesome/free-solid-svg-icons'


import DatePicker from 'react-date-picker';

import axios from "axios";

import swal from 'sweetalert';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {BriefcaseIcon, MortarBoardIcon, AlertIcon, PlusSmallIcon, BookIcon, FileIcon, GlobeIcon, Check, X} from 'react-octicons'

export default class AddInfo extends Component<Props> {
  state = {
    isLoggedIn: false,

    profileStatus: {
      missing_experience: true,
      missing_profile_picture: true
    },
    userdata: {},

    profile: {
      references: [],
      experience: []
    },


    experience: "",
    experienceType: "work",
    experienceDescription: "",
    date1: "",
    date2: "",
    showmodal: false
  }

  constructor(props) {
    super(props);
    this.loadUserData = this.loadUserData.bind(this);
    this.addToTimeline = this.addToTimeline.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.deleteFromTimeline = this.deleteFromTimeline.bind(this);
    this.handleClose = this.handleClose.bind(this);

    let event = new Event('loadUserData');
    window.addEventListener('loadUserData', this.loadUserData, false);

  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }

    this.detectProfile();
    this.loadUserData();
  }

  detectProfile() {
    axios.get("https://l16.vixo.no/api/public/get/tips").then(res => {
      console.log(res.data);
      this.setState({profileStatus: res.data});

      if(res.data.missing_experience == true) {
        this.setState({showmodal: true});
      }
    });
  }


  getProfile() {
    axios.get("https://l16.vixo.no/api/public/get/Profile/user/"+this.state.userdata.url).then(res => {
      this.setState({profile: res.data});

      let viewerData = JSON.parse(window.localStorage.getItem("userData"));
      if(viewerData !== null) {
        if(typeof viewerData.id !== 'undefined') {
          if(this.state.profile.id == viewerData.id) {
            this.setState({yourProfile: true});
          }
        }
      }
    });
  }



  loadUserData() {
    axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
      let data = res.data;

      if(res.data.success == true) {
        this.setState({userdata: res.data});
        //this.setState({isLoggedIn: true});
        this.getProfile();
      } else {
        //this.setState({isLoggedIn: false});
      }

      this.setState({loaded: true});

    })
  }


  addToTimeline() {

    let date1 = new Date(this.state.date1);
    date1 = JSON.stringify(date1)
    date1 = date1.slice(1,11)

    let date2 = new Date(this.state.date2);
    date2 = JSON.stringify(date2)
    date2 = date2.slice(1,11)

    let payload = new FormData();
    payload.append("type", this.state.experienceType);
    payload.append("title", this.state.experience);
    payload.append("text", this.state.experienceDescription);
    payload.append("from", date1);
    payload.append("to", date2);

    axios.post("https://l16.vixo.no/api/public/addexperience", payload).then(res => {
      if(res.data.success) {
        swal("Fullført!", "Du har nå lagt til en hendelse på din tidslinje!", "success");

        this.setState({experienceType: "work"});
        this.setState({experience: ""});
        this.setState({experienceDescription: ""});
        this.setState({date1: ""});
        this.setState({date2: ""});

        this.getProfile();
      } else {
        swal("Oida!", "Vi kunne ikke legge til hendelsen på din tidslinje! Har du fylt ut alle feltene?", "error");
      }
    });
  }

  deleteFromTimeline(item) {
    swal("Er du sikker på at du vil slette denne hendelsen?", {
      buttons: {
        cancel: "Avbryt",
        catch: {
          text: "Slett",
          value: "delete",
        }
      },
    })
    .then((value) => {
      if(value == "delete") {
        swal("Fullført!", "Hendelsen er nå slettet fra din tidslinje!", "success");

        console.log(item);

        let payload = new FormData();
        payload.append("id", item.id);

        axios.post("https://l16.vixo.no/api/public/v2/experience/delete", payload).then(res => {
          if(res.data.success) {
            swal("Fullført!", "Du har nå slettet en hendelse på din tidslinje!", "success");
            this.getProfile();
          } else {
            swal("Oida!", "Vi kunne ikke slette hendelsen på din tidslinje! Prøv igjen senere!", "error");
          }
        });
      }
    });
  }

  handleClose() {
    this.setState({showmodal: false});
  }


  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <Modal show={this.state.showmodal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Fyll på profilen din</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>Ønsker du drømmejobben? Gjør som andre - legg inn alt du har av kvalifikasjoner med et bilde/video på profilen!</p>

            <Form.Group controlId="formBasicEmail">
              <Form.Label><b>Tittel / Navn</b></Form.Label>
              <Form.Control type="text" placeholder="Olas bakeri" value={this.state.experience} onChange={(e) => {this.setState({experience: e.target.value})}} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label><b>Beskrivelse</b></Form.Label>
              <Form.Control as="textarea" rows="3" placeholder="Beskrivelse" value={this.state.experienceDescription} onChange={(e) => {this.setState({experienceDescription: e.target.value})}} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label><b>Type</b></Form.Label>
              <Form.Control as="select" value={this.state.experienceType} onChange={(e) => {this.setState({experienceType: e.target.value})}}>
                <option value="work">Arbeidsplass</option>
                <option value="education">Utdanning</option>
                <option value="project">Prosjekt</option>
                <option value="charity">Frivillig / Veldedighet</option>
                <option value="certification">Sertifisering</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label><b>Fra</b></Form.Label><br />
              <DatePicker onChange={(e) => {this.setState({date1: e})}} value={this.state.date1} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label><b>Til</b></Form.Label><br />
              <DatePicker onChange={(e) => {this.setState({date2: e})}} value={this.state.date2} />
              <br /><i>Hvis du ikke er ferdig behold denne blank.</i>
            </Form.Group>

            <Button variant="success" size="lg" block onClick={this.addToTimeline}>Legg til!</Button>


            <VerticalTimeline layout="1-column">

              {this.state.profile.experience.map(function(item, i){
                let icon = (<BriefcaseIcon fill="white" />);
                let color = "";
                let text = "";

                console.log("hm: " + this.state.yourProfile)

                switch(item.type) {
                  case "work":
                    icon = (<BriefcaseIcon fill="white" />);
                    color = "rgb(33, 150, 243)";
                    text = "Arbeidsplass";
                    break;
                  case "education":
                    icon = (<MortarBoardIcon fill="white" />);
                    color = "#449e43";
                    text = "Utdanning";
                    break;
                  case "course":
                    icon = (<BookIcon fill="white" />);
                    color = "#449e43";
                    text = "Kurs";
                    break;
                  case "project":
                    icon = (<FileIcon fill="white" />);
                    color = "rgb(33, 150, 243)";
                    text = "Prosjekt";
                    break;
                  case "charity":
                    icon = (<GlobeIcon fill="white" />);
                    color = "rgb(114, 116, 181)";
                    text = "Veldedighet";
                    break;
                  case "void":
                    icon = (<AlertIcon fill="white" />);
                    color = "#d26a5c";
                    break;
                  default:
                    // code block
                }

                return (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#f0f0f0', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid  #f0f0f0' }}
                    date=""
                    iconStyle={{ background: color, color: 'white' }}
                    icon={icon}
                  >
                    <h4 className="vertical-timeline-element-title">{item.title}</h4>
                    <h6 className="vertical-timeline-element-subtitle">{item.start} -  {item.finish == '1970-01-01' ? 'Nåværende' : item.finish}</h6>
                    <i>{text}</i>
                    <p>
                      {item.text}
                    </p>
                    <div className={`${this.state.yourProfile && item.type != "void" ? '':'hidden'} editTimeline`}>
                      <div></div>
                      <div className="action" onClick={(e) => {this.deleteFromTimeline(item)}}>Slett</div>
                    </div>
                  </VerticalTimelineElement>

                )
              }, this)}
            </VerticalTimeline>

          </Modal.Body>
        </Modal>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
