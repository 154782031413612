import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faSpinner } from '@fortawesome/free-solid-svg-icons'

import {BriefcaseIcon} from 'react-octicons'

import axios from "axios";

export default class Favorites extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: {
      success: true,
      list:[],
      posts:0,
      companies:0
    },
    search: "",
    searchTerm: "",
    toggleFilter: false,
    counties: [],
    municipalities: [],

    searched: false,
    loadingmore: false,

    county: 0,
    chosenMunicipalities: [],
    page: 1,
    lastPage: false,

    countyCount: [],
    municipalityCount: [],

    sector: "",
    hiretype: ""
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.search = this.search.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.getCounties = this.getCounties.bind(this);
    this.chooseCounty = this.chooseCounty.bind(this);
    this.chooseMunicipality = this.chooseMunicipality.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.loadPage = this.loadPage.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.search();
    this.getCounties();
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/position/"+id,
      job: job
    })
  }

  search() {
    this.setState({searched: false});
    this.setState({page: 1});
    this.setState({results: {
      success: true,
      list:[],
      posts:0,
      companies:0
    }})
    this.loadPage(1);
  }

  nextPage() {
    this.setState({loadingmore: true});
    let nextPage = this.state.page + 1;
    this.setState({page: nextPage});
    this.loadPage(nextPage);
  }

  loadPage(page) {

    this.setState({searchTerm: this.state.search});

    axios.post("https://l16.vixo.no/api/public/v2/get/favorite/posts").then(res => {
      let arr = this.state.results;
      arr.list = arr.list.concat(res.data.list);
      this.setState({results: arr});

      this.setState({searched: true});
      this.setState({loadingmore: false});
    });
  }

  toggleFilter() {
    if(this.state.showFilter == true) {
      this.setState({showFilter: false});
    } else {
      this.setState({showFilter: true});
    }
  }

  getCounties() {
    axios.get("https://l16.vixo.no/api/public/get/location/counties").then(res => {
      this.setState({counties: res.data});
    })
  }

  chooseCounty(id) {
    if(this.state.county == id) {
      this.setState({county: 0});
    } else {
      this.setState({county: id});
    }
    axios.get("https://l16.vixo.no/api/public/get/location/municipalities?county=" + id).then(res => {
      this.setState({municipalities: res.data});
      this.setState({chosenMunicipalities: []});
    })
  }

  chooseMunicipality(id) {
    console.log("Yeet");
    let chosen = this.state.chosenMunicipalities;

    if(chosen.includes(id)) {
      chosen.remove(id);
      this.setState({chosenMunicipalities: chosen});
    } else {
      chosen.push(id);
      this.setState({chosenMunicipalities: chosen});
    }
  }

  getCountyCount(id) {
    if(typeof this.state.countyCount[id] !== "undefined") {
      return this.state.countyCount[id];
    } else {
      return 0;
    }
  }

  getMunicipalityCount(id) {
    if(typeof this.state.municipalityCount[id] !== "undefined") {
      return this.state.municipalityCount[id];
    } else {
      return 0;
    }
  }

  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>

          <Card className="tcContainer">
            <Card.Body>
              <Card.Title>Favoritter</Card.Title>
              <Card.Subtitle className={`mb-2 text-muted ${this.state.searchTerm == "" ? 'hidden':''}`}>Viser {this.state.results.list.length} treff på "<i>{this.state.searchTerm}</i>"</Card.Subtitle>
              <Card.Text>

                <ListGroup>

                       {this.state.results.list.map((item) => (
                            <ListGroup.Item className="listGroupSegments" onClick={(e) => {this.openPosition(item.id, item)}}>
                              <div className={`lgLogo ${item.nav == true ? 'hidden':''}`} style={{backgroundImage: `url(https://l16.vixo.no/api/public/get/image/${item.logo})`}}></div>
                              <div className={`lgLogo ${item.nav == true ? '':'hidden'}`}><FontAwesomeIcon icon={faSuitcase} /></div>

                              <div className="lgContent">
                                <h4>{item.title}</h4>
                                <p className={item.description.length == 0 ? 'hidden':''}>{item.description}</p>
                                <p>{item.company}</p>
                              </div>

                              <div className={`lgAction ${item.isAd == false ? '' : 'hidden'}`}>
                                Frist: {item.due}
                              </div>
                            </ListGroup.Item>
                          )
                        )}

                </ListGroup>

                <div className={`loading ${this.state.loadingmore == true ? '':'hidden'}`} style={{"textAlign": "center"}}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>

                <div className={this.state.results.list.length == 0 && this.state.searched == true ? '':'hidden'} style={{"textAlign": "center"}}>
                  <h3>Du har ingen favoritter!</h3>
                </div>

                <div className={`loading ${this.state.results.list.length == 0 && this.state.searched == false ? '':'hidden'}`} style={{"textAlign": "center"}}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
