import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './position.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import swal from 'sweetalert';
import {Helmet} from "react-helmet";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSuitcase, faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

export default class Position extends Component<Props> {

  state = {
    showApplication: false,
    job: {
      alreadyApplied: false,
      nav: false
    },
    isLoggedIn: false,
    application: "",
    favorite: false
  }

  constructor(props) {
    super(props);

    this.openApplicationForm = this.openApplicationForm.bind(this);
    this.sendApplication = this.sendApplication.bind(this);
    this.onChange = editorState => this.setState({editorState});
    this.openNav = this.openNav.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
  }

  componentDidMount() {
    if(typeof this.props.location.job !== 'undefined') {
      //this.setState({job: this.props.location.job});
    }

    this.loadUserData();
    this.loadPosition();
  }

  componentWillUnmount() {
    document.title = "The Connector - Portal";
  }

  stateChange(e){
    console.log(e);
  }

  openApplicationForm() {
    if(this.state.showApplication == true) {
      this.setState({showApplication: false});
    } else {
      this.setState({showApplication: true});
    }
  }

  sendApplication() {
    let data = new FormData();
    data.append("text", this.state.application);
    data.append("postid", this.props.match.params.id);
    axios.post("https://l16.vixo.no/api/public/v2/application/send", data).then(res => {
      if(res.data.success == true) {
        this.props.history.push("/");
        swal("Søknaden er sendt!", "Din søknad er nå sendt, lykke til!", "success")
      } else {
        swal('Oida!', 'Noe uventet har oppstått, vennligst prøv igjen senere!', 'error');
      }
    }).catch(e => {
      swal('Oida!', 'Noe uventet har oppstått, vennligst prøv igjen senere!', 'error');
    });
  }

  openNav() {
    swal('Du blir nå sendt til et nettsted utenfor TheConnector!', 'Denne annonsen sender deg ut av TheConnector, husk at du kan laste ned din CV fra TheConnector som du kan benytte andre steder!', 'info').then(res => {
      console.log("Ok");
      window.open("https://arbeidsplassen.nav.no/stillinger/stilling/"+this.state.job.token,'_blank');
    });
  }

  handleEditorChange(content, editor) {
     console.log('Content was updated:', content);
   }

   loadPosition() {
     axios.get("https://l16.vixo.no/api/public/v2/get/job/" + this.props.match.params.id).then(res => {
       this.setState({job: res.data.data});
       this.setState({favorite: res.data.data.favorite});

       let title = "";

       if(res.data.data.position != "") {
         title += res.data.data.position + " - ";
       }

       if(res.data.data.title != "") {
         title += res.data.data.title + " - ";
       }

       document.title = title + 'The Connector';
     });
   }

   loadUserData() {
     let data = localStorage.getItem("userData");
     data = JSON.parse(data);
     if(typeof data !== undefined && data !== null) {
       if(typeof data.success !== undefined) {
         this.setState({isLoggedIn: data.success});
       }
     }


     axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
       let data = res.data;

       if(res.data.success == true) {
         this.setState({isLoggedIn: true});
       } else {
         this.setState({isLoggedIn: false});
       }
     })
   }

   renderHireType() {
     switch(this.state.job.hiretype) {
       case "1":
        return "Heltid";
        break;

      case "2":
        return "Deltid";
        break;

      case "3":
        return "Kontrakt";
        break;

      case "4":
        return "Midlertidig";
        break;

      case "5":
        return "Praktikant";
        break;

      case "6":
        return "Frivillig";
        break;

      case "7":
        return "Timesbasert";
        break;
      default:
        return "Uspesifisert heltid/deltid";
        break;
     }
   }

  toggleFavorite() {
    if(this.state.favorite) {
      this.setState({favorite: false});
      let payload = new FormData();
      payload.append("id", this.props.match.params.id);

      axios.post("https://l16.vixo.no/api/public/v2/remove/favorite/post", payload).then(res => {

      });
    } else {
      this.setState({favorite: true});

      let payload = new FormData();
      payload.append("id", this.props.match.params.id);
      axios.post("https://l16.vixo.no/api/public/v2/add/favorite/post", payload).then(res => {
      });
    }
  }

  render() {
    return(
      <div>
                <Card className="tcContainer">
                  <Card.Body>
                  <Alert variant="success" className={``}>
                      <Alert.Heading>Anonym søknadsliste</Alert.Heading>
                      <p>
                        Vi ønsker å sikre en rettferdig og inkluderende rekrutteringsprosess, derfor har vi aktivert en anonymisert søknadsliste for denne stillingen. Dette betyr at alle søknader vil bli vurdert utelukkende på grunnlag av kvalifikasjoner og erfaring, uten hensyn til personlige opplysninger som navn, kjønn og nasjonalitet. Vi oppfordrer alle kvalifiserte søkere til å søke på denne stillingen og benytte denne muligheten for en likestilt vurderingsprosess. Vi ser frem til å motta din søknad og vurderer den på lik linje med alle andre kvalifiserte søkere.
                      </p>
                      <hr />
                      <p className="mb-0">
                        Arbeidsgiveren vil kunne kontakte deg direkte dersom du er blant de kvalifiserte søkerne.
                      </p>
                    </Alert>
                    <div className="positionWrapper">
                      <div className="company">
                      <div className={`ProfileSettings`}>
                        <div className={`editProfile ${this.state.isLoggedIn == false ? 'hidden':''}`} onClick={this.toggleEditProfile}>
                          Favoritt <FontAwesomeIcon icon={this.state.favorite ? faStar : farStar } onClick={(e) => {this.toggleFavorite()}} className={this.state.favorite ? 'starFilled' : ''} />
                        </div>
                      </div>
                        <center>
                          <img width="150px" src={`https://l16.vixo.no/api/public/get/image/${this.state.job.logo}`} className={`${this.state.job.nav == false ? '':'hidden'}`} /><br />
                            <div className={`lgLogo positionPagelgLogo ${this.state.job.nav == true ? '':'hidden'}`}><FontAwesomeIcon icon={faSuitcase} /></div>
                            <div className={`${this.state.job.nav == false ? '':'hidden'}`}>
                              <Button className={`${this.state.job.alreadyApplied == true ? 'hidden' : ''} ${this.state.isLoggedIn == false ? 'hidden':''}`} variant="primary" onClick={this.openApplicationForm} style={{marginTop: "20px"}}>Send søknad</Button>
                              <Button className={`${this.state.job.alreadyApplied == true ? '' : 'hidden'}`} variant="primary" style={{marginTop: "20px"}} disabled>Du har allerede søkt</Button>
                            </div>

                            <div className={`${this.state.job.nav == false ? 'hidden':''} ${this.state.isLoggedIn == false ? 'hidden':''}`}>
                              <Button variant="primary" onClick={this.openNav} style={{marginTop: "20px"}}>Les mer på Nav.no</Button>
                            </div>

                            <div className={`${this.state.isLoggedIn == false ? '':'hidden'}`}>
                              <Button variant="primary" onClick={() => { this.props.history.push("/login"); }} style={{marginTop: "20px"}}>Logg inn</Button><br />
                              Du må være logget på for å søke på denne stillingen!
                            </div>

                          <h4 style={{marginTop: "20px"}}>{this.state.job.company}</h4>
                          <h5>{this.state.job.position}</h5>
                          <h6>{this.renderHireType()}</h6>
                        </center>
                        <p className={this.state.job.compensation != null ? '':'hidden'}><b>Lønn:</b><br /> {this.state.job.compensation}</p>
                        <p><b>Addresse:</b><br /> {this.state.job.location}</p>
                        <p className={this.state.job.due != "" ? '':'hidden'}><b>Søknadsfrist:</b><br /> {this.state.job.due == '01-01-1970' ? 'Ukjent' : this.state.job.due}</p>
                        <p>{this.state.job.description}</p>
                      </div>
                      <div className="position">
                        <div className={`${this.state.showApplication == false ? '': 'hidden'}`}>
                          <h3>{this.state.job.title}</h3>
                          <p id="positionText" dangerouslySetInnerHTML={{__html: this.state.job.text}}></p>
                          <p dangerouslySetInnerHTML={{__html: this.state.job.requiretext}}></p>
                        </div>

                        <div className={`${this.state.showApplication == false ? 'hidden': ''}`}>
                          <h3>{this.state.job.position} - {this.state.job.company}</h3>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Søknadsbrev</Form.Label>

                              <CKEditor
                                editor={ ClassicEditor }
                                data=""
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    //console.log( { event, editor, data } );
                                    this.setState({application: data})
                                } }
                            />
                          </Form.Group>

                          <Button variant="success" size="lg" onClick={this.sendApplication} block>Send!</Button>

                        </div>

                      </div>
                    </div>
                  </Card.Body>
                </Card>

      </div>
    );
  }
}
