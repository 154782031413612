import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import "./map.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faSpinner } from '@fortawesome/free-solid-svg-icons'

import {BriefcaseIcon} from 'react-octicons'

import axios from "axios";

import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

export default class Map extends Component<Props> {
  state = {
    show: false,
    pos: {
      lat: 59.9139,
      lng: 10.7522
    },
    lat1: 0,
    lat2: 0,
    lng1: 0,
    lng2: 0,
    map: null,

    results: {
      success: true,
      list:[],
      posts:0,
      companies:0
    },

    results2: {
      success: true,
      list:[],
      posts:0,
      companies:0
    },

    added: [],

    infoWindow: 0
  }

  constructor(props) {
    super(props);

    this.search = this.search.bind(this);
    this.ProjChange = this.ProjChange.bind(this);
    this.updateLatLng = this.updateLatLng.bind(this);
  }


  loadPage(page = 1, query = false) {
    this.setState({searchTerm: this.state.search});

    let params = {
      query: this.props.home.state.search,
      sort: {
          "nav": "asc"
        },
      filters : {
        all: [
          {
            coords: {
              center: ((this.state.lat1 + this.state.lat2) / 2) + ", " + ((this.state.lng1 + this.state.lng2) / 2),
              distance: 300,
              unit: "km"
            }
          }
        ],
      },
      page: {
        current: page,
        size: 20
      }
    }

    if(query != false) {
      params.query = query;
      console.log("We changed query.");
    }

    if(this.props.home.state.chosenMainCats.length != 0) {
      params.filters.all.push({"cat1": this.props.home.state.chosenMainCats});
    }

    if(this.props.home.state.chosenSubCats.length != 0) {
      params.filters.all.push({"cat2": this.props.home.state.chosenSubCats});
    }

    if(this.props.home.state.chosenMunicipalities.length != 0) {
      params.filters.all.push({"municipality": this.props.home.state.chosenMunicipalities});
    }

    if(this.props.home.state.county.length != 0) {
      params.filters.all.push({"county": this.props.home.state.county});
    }

    if(this.props.home.state.sector != "0" && this.state.sector != "") {
      params.filters.all.push({"sector": this.props.home.state.sector});
    }

    axios.post("https://enterprise-search-deployment-5aff60.ent.europe-north1.gcp.elastic-cloud.com/api/as/v1/engines/theconnectorposts/search", params, {
      headers: {
        Authorization: 'Bearer search-c1br28yosdu1x9r2qtxinnn7', //the token is a variable which holds the token
        'Content-Type': 'application/json'
      }
    }).then(res => {
      let arr = this.state.results2;
      arr.list = arr.list.concat(res.data.results);

      this.setState({searched: true});
      this.setState({loadingmore: false});
    });
  }

  search() {
    let data = new FormData();
    data.append("query", this.props.home.state.search);
    data.append("sector", this.props.home.state.sector);
    data.append("hiretype", this.props.home.state.hiretype);
    data.append("tansitmethod", "NONE");
    data.append("traffic", "TRAFFIC_FREE");
    data.append("traveltime", "");
    data.append("category", "");
    data.append("compensation", "NONE");
    data.append("county", this.props.home.state.county);
    data.append("municipalities", this.props.home.state.chosenMunicipalities);
    data.append("lat1", this.state.lat1);
    data.append("lat2", this.state.lat2);
    data.append("lng1", this.state.lng1);
    data.append("lng2", this.state.lng2);


    axios.post("https://l16.vixo.no/api/public/v2/get/user/jobs", data).then(res => {
      let arr = this.state.results;

      arr.list = res.data.list;
      this.setState({results: arr});
    });

    this.loadPage();
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          /*infoWindow.setPosition(pos);
          infoWindow.setContent("Location found.");
          infoWindow.open(map);
          map.setCenter(pos);*/
          this.ProjChange();

          console.log(pos);
          this.setState({pos: pos});
        },
        () => {
          //test
        }
      );
    } else {
      // Browser doesn't support Geolocation
    }


    this.ProjChange();

    window.addEventListener('mapChange', this.ProjChange, false);
  }

  ProjChange(e) {

    //console.log(window.mapRef.getBounds());

    //let mapBounds = window.mapRef.getBounds();


    /*this.setState({lat1: mapBounds.Ya.i});
    this.setState({lat2: mapBounds.Ya.j});

    this.setState({lng1: mapBounds.Sa.i});
    this.setState({lng2: mapBounds.Sa.j});*/

    //this.search();
  }


  updateLatLng(lat1) {
    this.setState({lat1: lat1})
  }


  infoWindow(item) {
    if(this.state.infoWindow == item.id) {

      return (
        <InfoWindow onCloseClick={(r) => {this.setState({infoWindow: 0})}}>
          <div>
            <h3>{item.title}</h3>
            <p className={item.description.length == 0 ? 'hidden':''}>{item.description}</p>
            <p>{item.company}</p>
            <Link to={`/position/` + item.id}>Se annonsen!</Link>
          </div>
        </InfoWindow>
      )

    } else {
      return null;
    }
  }

  Map = withGoogleMap(props => (
    <GoogleMap
      ref={(map) => {
        window.mapRef = map;
        this._map = map;
      }}
      defaultCenter = { { lat: this.state.pos.lat, lng: this.state.pos.lng } }
      defaultZoom = { 11 }
      onDragEnd={(r,a) => {
        let bounds = this._map.getBounds();

        let NECorner = bounds.getNorthEast();
        let SWCorner = bounds.getSouthWest();


        let lat1 = NECorner.lat();
        let lat2 = SWCorner.lat();

        let lng1 = SWCorner.lng();
        let lng2 = NECorner.lng();


        this.setState({lat1: lat1});
        this.setState({lat2: lat2});

        this.setState({lng1: lng1});
        this.setState({lng2: lng2});

        this.search();
      }}

      onZoomChanged={(r,a) => {
        let bounds = this._map.getBounds();

        let NECorner = bounds.getNorthEast();
        let SWCorner = bounds.getSouthWest();


        let lat1 = NECorner.lat();
        let lat2 = SWCorner.lat();

        let lng1 = SWCorner.lng();
        let lng2 = NECorner.lng();


        this.setState({lat1: lat1});
        this.setState({lat2: lat2});

        this.setState({lng1: lng1});
        this.setState({lng2: lng2});

        this.search();
      }}
      onMouseOver={(r,a) => {
        let bounds = this._map.getBounds();

        let NECorner = bounds.getNorthEast();
        let SWCorner = bounds.getSouthWest();


        let lat1 = NECorner.lat();
        let lat2 = SWCorner.lat();

        let lng1 = SWCorner.lng();
        let lng2 = NECorner.lng();


        this.setState({lat1: lat1});
        this.setState({lat2: lat2});

        this.setState({lng1: lng1});
        this.setState({lng2: lng2});

        this.search();
      }}
    >
    {this.state.results.list.map((item) => (
      <Marker
        position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
        onClick={(r) => {this.setState({infoWindow: item.id})}}
        key={item.id}
      >
        {this.infoWindow(item)}
      </Marker>
    ))}
    </GoogleMap>
 ));

  render() {
      return(
        <div className="mapContainer">

          <div className={`mapfilter`}>

            <Button variant="light" onClick={(e) => { this.props.home.setState({showMap: false}) }}>
              Lukk
            </Button>

            <br /><br />

            <h3>Filter</h3>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control type="text" placeholder="F.eks IT, Apputvikler, Account manager, Olje" value={this.props.home.state.search} onChange={(e) => {this.props.home.setState({search: e.target.value})}} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Sektor:</Form.Label>
              <Form.Control as="select" value={this.props.home.state.sector} onChange={(e) => {this.props.home.setState({sector: e.target.value})}}>
                <option value="">Alle</option>
                <option value="Offentlig">Offentlig</option>
                <option value="Privat">Privat</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Stillingstype:</Form.Label>
              <Form.Control as="select" value={this.props.home.state.hiretype} onChange={(e) => {this.props.home.setState({hiretype: e.target.value})}}>
                <option value="">Alle</option>
                <option value="1">Heltid</option>
                <option value="2">Deltid</option>
              </Form.Control>
            </Form.Group>

            <h4>Fylke:</h4>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Control as="select" value={this.props.home.state.county} onChange={(e) => {this.props.home.chooseCounty(e.target.value)}}>
                <option value="">Alle</option>
                {this.props.home.state.counties.map((county) => (
                  <option value={county.id}>{county.county} ({this.props.home.getCountyCount(county.id)})</option>
                ))}
              </Form.Control>
            </Form.Group>

            <h4 className={this.props.home.state.county != 0 ? '':'hidden'}>Kommune:</h4>
            <div class={`mapMunicipality ${this.props.home.state.county != 0 ? '':'hidden'}`}>
              <div className={`municipalities counties ${this.props.home.state.county != 0 ? '':'hidden'}`}>
                {this.props.home.state.municipalities.map((municipality) => (
                  <div className={`county noselect ${this.props.home.state.chosenMunicipalities.includes(municipality.id) ? 'active' : ''} ${!this.props.home.state.chosenMunicipalities.includes(municipality.id) && this.props.home.state.chosenMunicipalities.length != 0 ? 'notSelected' : ''} ${this.props.home.getMunicipalityCount(municipality.id) != 0 ? 'glow':'notSelected'}`} onClick={(e) => { this.props.home.chooseMunicipality(municipality.id) }}>{municipality.municipality} ({this.props.home.getMunicipalityCount(municipality.id)})</div>
                ))}
              </div>
            </div>

            <Button variant="primary" onClick={this.props.home.search} className="mapSearchBtn">
              Søk
            </Button>


          </div>

          <div className={`map`}>
            <this.Map
              containerElement={ <div style={{ height: `100vh`, width: '80vw' }} /> }
              mapElement={ <div style={{ height: `100%` }} /> }
            />
          </div>
        </div>
      );
  }
}
