import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import "./home.css";

import axios from "axios";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "./createposition.css";
import swal from 'sweetalert';

export default class RecruiterCreatePosition extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: [],

    stage: "company",

    showLogoPicker: false,

    showCompanyPicker: false,
    companies: [],
    company: {
      navn: ""
    },
    media: [],
    logo: {
      id: 0,
      name: ""
    },

    title: "",
    ingress: "",
    adress: "",
    text: "",
    position: "",
    hiretype: "1",
    salary: "0",
    due: "",
    requiretext: "",

    file: "",
    fileObject: "",
    categories: [],
    subCategories: [],
    categoryType: 64,
    subCategoryType: 0,

    isAnonymous: false
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.SearchCompany = this.SearchCompany.bind(this);
    this.nextStage = this.nextStage.bind(this);
    this.backStage = this.backStage.bind(this);
    this.create = this.create.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.post = this.post.bind(this);
  }


  create() {
    let data = new FormData();
    data.append('file', this.state.fileObject);

    axios.post("https://l16.vixo.no/api/public/upload/image", data).then(res => {
      if(res.data.success == true) {
        this.post(res.data.id);
      } else {
        swal("Oida!", "Vi klarte ikke å publisere denne annonsen, ta gjerne kontakt via chat!<br /> <br /> kode: 0002", "error");
      }
    });
  }

  post(image) {
    let isAnon = 0;
    if(this.state.isAnonymous == true) {
      isAnon = 1;
    }

    let data = new FormData();
    data.append("title", this.state.title);
    data.append("text", this.state.text);
    data.append("ingress", this.state.ingress);
    data.append("requiretext", this.state.requiretext);
    data.append("position", this.state.position);
    data.append("location", this.state.adress);
    data.append("hiretype", this.state.hiretype);
    data.append("logo", image);
    data.append("due", this.state.due);
    data.append("company", JSON.stringify(this.state.company));
    data.append("cat1", this.state.categoryType);
    data.append("cat2", this.state.subCategoryType);
    data.append("isAnonymous", isAnon);

    //companyid: $scope.companyid,


    axios.post("https://l16.vixo.no/api/public/v2/create/job", data).then(res => {
      console.log(res);
      if(res.data.success == true) {
        swal("Fullført!", "Annonsen er nå publisert! Det kan ta opptil fem minutter før den vises i søkemotoren.", "success");
        this.props.history.push("/recruiter/position/"+res.data.id);
      } else {
        swal("Oida!", "Vi klarte ikke å publisere denne annonsen, ta gjerne kontakt via chat!<br /> <br /> kode: 0001", "error");
      }
    })
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.loadMedia();
    this.loadCategories();
    this.loadSubCategories(64);
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/recruiter/position/"+id,
      job: job
    })
  }

  loadCategories() {
    axios.get("https://l16.vixo.no/api/public/v2/get/categories").then(res => {
      this.setState({categories: res.data});
    })
  }

  loadSubCategories(e) {
    axios.get("https://l16.vixo.no/api/public/v2/get/subcategories?id="+e).then(res => {
      this.setState({subCategories: res.data});
    })
  }

  handleClose() {
    this.setState({showCompanyPicker: false});
  }

  handleCloseLogo() {
    this.setState({showLogoPicker: false});
  }

  SearchCompany(value) {
    console.log(value);
    axios.get("https://data.brreg.no/enhetsregisteret/api/enheter?navn=" + value).then(res => {
      if(typeof res.data._embedded !== 'undefined') {
        this.setState({companies: res.data._embedded.enheter});
      }
    });
  }

  loadMedia() {
    axios.get("https://l16.vixo.no/api/public/get/media").then(res => {
      this.setState({media: res.data.images});
    });
  }

  nextStage() {
    if(this.state.stage == "company") {
      this.setState({stage: "position"});
    } else if (this.state.stage == "position") {
      this.create();
    }
  }

  backStage() {
    if(this.state.stage != "company") {
      this.setState({stage: "company"});
    }
  }

  onDrop(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    })

    this.setState({
      fileObject: event.target.files[0]
    })
  }

  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>
          <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
            <Card.Body>
              <div className="topContainer">
                <div className="left">
                  <Card.Title>Opprett utlysning</Card.Title>
                </div>

                <div className="right" style={{textAlign: "right"}}>
                  <Button variant="primary" onClick={this.nextStage}>{this.state.stage == "company" ? "Neste steg" : "Opprett"}</Button>
                </div>
              </div>
              <Card.Text>

                <Form className={this.state.stage == "company" ? "":"hidden"}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Firma</Form.Label><br />
                    {this.state.company.navn}<br />
                    <Button variant="primary" onClick={(e) => { this.setState({showCompanyPicker: true}) }}>Velg bedrift</Button>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Logo</Form.Label><br />
                      <div className={`galleryItem ${this.state.file != "" ? "": "hidden"} `}>
                        <img src={this.state.file} />
                      </div>

                    <input type="file" onChange={this.onDrop}/>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Utlysningstittel</Form.Label>
                    <Form.Control type="text" onChange={(e) => { this.setState({title: e.target.value}) }} placeholder="En rask beskrivelse av bedriften." />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Ingress</Form.Label>
                    <Form.Control type="text" onChange={(e) => { this.setState({ingress: e.target.value}) }} placeholder="En rask beskrivelse av bedriften." />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Adressen til arbeidsplassen</Form.Label>
                    <Form.Control type="text" placeholder="Norgesveien 4, oslo" onChange={(e) => { this.setState({adress: e.target.value}) }} />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Skriv litt om bedriften og hvordan det er å jobbe der.</Form.Label>
                      <CKEditor
                        editor={ ClassicEditor }
                        data=""
                        config={{
                          toolbar: {
                            items: [
                                'heading', '|',
                                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                'link', '|',
                                'outdent', 'indent', '|',
                                'bulletedList', 'numberedList', '|',
                                'insertTable', '|',
                                'blockQuote', '|',
                                'mediaEmbed', '|',
                                'undo', 'redo'
                            ],
                          }
                        }}
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            //console.log( { event, editor, data } );
                            this.setState({text: data})
                        } }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 anonCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Anonym søknadsliste"
                      checked={this.state.isAnonymous}
                      onClick={(e) => { this.setState({isAnonymous: !this.state.isAnonymous}) }}
                    />
                    <br />
                    <p><i>Ved å velge denne funksjonen vil alle søknader på denne stillingen bli presentert på en anonymisert liste, hvor navn, kjønn, nasjonalitet og andre personlige opplysninger vil være skjult. Dette sikrer at alle søkere blir vurdert utelukkende på grunnlag av deres kvalifikasjoner og erfaring, og gir en rettferdig og likestilt vurderingsprosess. Vi oppfordrer alle arbeidsgivere til å velge denne funksjonen for å sikre en inkluderende og diskrimineringsfri rekrutteringsprosess.</i></p>
                  </Form.Group>
                </Form>




                <Form className={this.state.stage == "position" ? "":"hidden"}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Stillingstittel</Form.Label><br />
                    <Form.Control type="text" placeholder="Olas bakeri" onChange={(e) => { this.setState({position: e.target.value}) }} />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Stillingstype</Form.Label><br />
                    <Form.Control as="select" onChange={(e) => { this.setState({hiretype: e.target.value}) }}>
                      <option value="1">Fulltid</option>
                      <option value="2">Deltid</option>
                      <option value="3">Kontrakt</option>
                      <option value="4">Midlertidig</option>
                      <option value="5">Praktikant</option>
                      <option value="6">Frivillig</option>
                      <option value="7">Timesbasert</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Kategori</Form.Label><br />
                    <Form.Control as="select" value={this.state.categoryType} onChange={(e) => { this.setState({categoryType: e.target.value}); this.loadSubCategories(e.target.value); }}>
                      {
                        this.state.categories.map((category) => {
                          return <option value={category.id}>{category.name}</option>
                        })
                      }
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Underkategori</Form.Label><br />
                    <Form.Control as="select" onChange={(e) => { this.setState({subCategoryType: e.target.value}) }}>
                      {
                        this.state.subCategories.map((category) => {
                          return <option value={category.id}>{category.name}</option>
                        })
                      }
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Lønn (Valgfri)</Form.Label><br />
                    <Form.Control type="text" placeholder="350 000" onChange={(e) => { this.setState({salary: e.target.value}) }} />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Søknadsfrist</Form.Label><br />
                    <Form.Control type="text" placeholder="F.eks Snarest / Vi vurderer løpende søknader" onChange={(e) => { this.setState({due: e.target.value}) }} />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Skriv litt om stillingen, hva kreves og hva ønskes av arbeidstakeren?</Form.Label>
                      <CKEditor
                        editor={ ClassicEditor }
                        data=""
                        config={{
                          toolbar: {
                            items: [
                                'heading', '|',
                                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                'link', '|',
                                'outdent', 'indent', '|',
                                'bulletedList', 'numberedList', '|',
                                'insertTable', '|',
                                'blockQuote', '|',
                                'mediaEmbed', '|',
                                'undo', 'redo'
                            ],
                          }
                        }}
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            //console.log( { event, editor, data } );
                            this.setState({requiretext: data})
                        } }
                    />
                  </Form.Group>
                </Form>


                <div className="topContainer">
                  <div className={`left ${this.state.stage != "company" ? '':'hidden'}`} style={{textAlign: "left"}}>
                    <Button variant="danger" onClick={this.backStage}>Forrige steg</Button>
                  </div>

                  <div className="right" style={{textAlign: "right"}}>
                    <Button variant="primary" onClick={this.nextStage}>{this.state.stage == "company" ? "Neste steg" : "Opprett"}</Button>
                  </div>
                </div>


              </Card.Text>
            </Card.Body>
          </Card>



          <Modal show={this.state.showCompanyPicker} onHide={this.handleCloseLogo}>
            <Modal.Header closeButton>
              <Modal.Title>Velg bedrift</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control type="text" placeholder="Olas bakeri" onChange={(e) => {
                      this.SearchCompany(e.target.value);
                    }} />
                </Form.Group>

                <ListGroup>
                       {this.state.companies.map((item) => (
                            <ListGroup.Item onClick={(e) => {this.setState({company: item}); this.setState({showCompanyPicker: false})}}>
                              <div className="lgContent">
                                <h4>{item.navn}</h4>
                                <p>{item.organisasjonsnummer}</p>
                              </div>
                            </ListGroup.Item>
                          )
                        )}
                </ListGroup>
              </Form>
            </Modal.Body>
          </Modal>


          <Modal show={this.state.showLogoPicker} onHide={this.handleCloseLogo}>
            <Modal.Header closeButton>
              <Modal.Title>Velg logo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.media.map((item) => (
                <div className="galleryItem gFloat" onClick={(e) => {this.setState({logo: item}); this.setState({showLogoPicker: false})}}>
                  <img src={`https://l16.vixo.no/api/public/get/image/${item.name}`} />
                </div>
                 )
               )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
