import React, { Component } from 'react';
import { Card, Button, ListGroup, Table, Form } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import "./home.css";
import "./positions.css";

import axios from "axios";

export default class RecruiterPositions extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: [],
    additional: {
      role: ''
    },
    filter: 'latest',
    showAll: false,
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.createposition = this.createposition.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.search();

    axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
      this.setState({additional: res.data});
    }).catch(function (error) {
      //swal("Oida!", "Vi kunne ikke laste inn profilen, prøv igjen senere!", "error");
    });
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/recruiter/position/"+id,
      job: job
    })
  }

  search(all = false, filter = this.state.filter) {
    this.setState({showAll: all});

    if(this.state.filter !== filter) {
      this.setState({filter: filter});
    }

    axios.get("https://l16.vixo.no/api/public/get/recruiter/posts?all=" + all + "&filter=" + filter).then(res => {
      this.setState({results: res.data});
    });
  }

  createposition() {
    this.props.history.push("/recruiter/create/position");
  }

  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>

          <Card style={{ width: '1000px', marginTop: "4vh", marginLeft: 'auto', marginRight: 'auto' }}>
            <Card.Body>
              <div className="topContainer">
                <div className="left">
                  <Card.Title className={this.state.results.length == 0 ? 'hidden':''}>Utlysninger</Card.Title>
                  <Card.Subtitle className={`mb-2 text-muted ${this.state.results.length == 0 ? 'hidden':''}`}>{this.state.showAll ? '':'Du har'} {this.state.results.length} utlysninger!</Card.Subtitle>
                </div>

                <div className="right" style={{textAlign: "right"}}>
                  <Button variant="success" onClick={(e) => {this.search(!this.state.showAll);}} className={`${this.state.additional.role == 'admin' ? '':'hidden'}`}>{this.state.showAll ? 'Vis dine':'Se alle'}</Button> <Button variant="primary" onClick={this.createposition}>Opprett utlysning</Button><br />
                  <Form.Control className="sortSelect" as="select" value={this.state.filter} onChange={(e) => {this.search(this.state.showAll, e.target.value);}}>
                    <option value="none">Ingen</option>
                    <option value="latest">Nyligste</option>
                    <option value="oldest">Eldste</option>
                  </Form.Control>
                </div>
              </div>
              <Card.Text>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Logo</th>
                      <th>Tittel</th>
                      <th>Stilling</th>
                      <th>Søkere</th>
                      <th>Publisert</th>
                      <th>Utløper</th>
                    </tr>
                  </thead>
                  <tbody>

                    {this.state.results.map((item) => (
                           <tr onClick={(e) => {this.openPosition(item.id, item)}}>
                             <td><img src={`https://l16.vixo.no/api/public/get/image/${item.logo}`} height="40px" /></td>
                             <td>{item.title}</td>
                             <td>{item.position}</td>
                             <td>{item.applications}</td>
                             <td>{item.posted}</td>
                             <td>{item.end}</td>
                           </tr>
                       )
                     )}

                  </tbody>
                </Table>

                <div className={this.state.results.length == 0 ? '':'hidden'} style={{"textAlign": "center"}}>
                  <h3>Oida! Her var det ikke mange utlysninger, prøv igjen senere!</h3>
                  <p>Kanskje du ønsker å se over profilen din, en ryddig og oppdatert profil er viktig!</p>
                  <p style={{"paddingLeft": "20%", "paddingRight": "20%"}}>Du kan motta meldinger selvom det ikke er noen aktive utlysninger i portalen, hodejegere kan søke etter kompetanse på stillinger som ikke ligger offentlig.</p>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
