import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './register.css';

export default class Register extends Component<Props> {

  state = {
    email: "",
    password: "",
    success: true,
    msg: "",
    registerType: ''
  }

  constructor(props) {
    super(props);
    this.choose = this.choose.bind(this);
  }

  componentDidMount() {
  }

  stateChange(e){
    console.log(e);
  }

  choose(type) {
    this.setState({registerType: type});
  }

  render() {
    return(
      <div className="login-wrapper">
        <Card style={{ width: '100%' }}>
          <Card.Body>

            <div className="typePicker">
              <div className="jobseeker">
                Jobbsøker<br />
                <Button variant={`${this.state.registerType == 'jobseeker' ? 'primary' : 'outline-primary'}`} onClick={(e) => {this.choose('jobseeker')}}>Velg</Button>
              </div>

              <div className="headhunter">
                Hodejeger<br />
                <Button variant={`${this.state.registerType == 'headhunter' ? 'primary' : 'outline-primary'}`} onClick={(e) => {this.choose('headhunter')}}>Velg</Button>
              </div>

              <div classname="company">
                Firma<br />
                <Button variant={`${this.state.registerType == 'company' ? 'primary' : 'outline-primary'}`} onClick={(e) => {this.choose('company')}}>Velg</Button>
              </div>
            </div>

            <Form>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            </Form>

          </Card.Body>
        </Card>

      </div>
    );
  }
}
