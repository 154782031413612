import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class userMenu extends Component<Props> {
  state = {
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <Link to="/">Utlysninger</Link>
        <Link to="/login">Firmaer</Link>
        <Link to="/login">Medlemsskap</Link>
      </div>
    )
  }
}
