import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faSpinner } from '@fortawesome/free-solid-svg-icons'

import {BriefcaseIcon} from 'react-octicons'

import axios from "axios";

import Chart from "react-google-charts";

import "./statistics.css";

export default class AdminStatistics extends Component<Props> {
  state = {
    isLoggedIn: false,
    userdata: {},
    registrations: [],
    genders: {0: ["",""],1: ["",""],2: ["",""],3: ["",""]},

    level1: [],
    level2: [],

    userlevel1: [],
    userlevel2: [],

    malelevel1: [],
    femalelevel1: [],

    imports: [],
    posts: 0,
    navPosts: 0,
    searches: 0,
    applications: 0,
    year: "2022",

    showNav: true
  }

  constructor(props) {
    super(props);
    this.loadUserData = this.loadUserData.bind(this);
    this.loadStatistics = this.loadStatistics.bind(this);

    let event = new Event('loadUserData');
    window.addEventListener('loadUserData', this.loadUserData, false);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.loadUserData();
    this.loadStatistics(this.state.year);
  }


  loadStatistics(year = this.state.year, nav = this.state.showNav) {
    if(year != this.state.year) {
      this.setState({year: year});
    }

    axios.get("https://l16.vixo.no/api/public/v2/get/full/statistics?year=" + year +"&showNav="+nav).then(res => {

      let regData = [[{ type: 'date', id: 'Date' }, { type: 'number', id: 'Registreringer' }]];
      for (var i = 0; i < res.data.registrations.length; i++) {
        regData.push([new Date(res.data.registrations[i][0]), parseInt(res.data.registrations[i][1])]);
      }
      this.setState({registrations: regData})

      let importData = [[{ type: 'date', id: 'Date' }, { type: 'number', id: 'Importeringer' }]];
      for (var i = 0; i < res.data.imports.length; i++) {
        importData.push([new Date(res.data.imports[i][0]), parseInt(res.data.imports[i][1])]);
      }
      this.setState({imports: importData})



      let genderData = [['Kjønn', 'Antall']];
      for (var i = 0; i < res.data.genders.length; i++) {
        genderData.push([res.data.genders[i][0], parseInt(res.data.genders[i][1])]);
      }
      this.setState({genders: genderData})

      let level1Data = [['Sektor', 'Antall']];
      for (var i = 0; i < res.data.level1.length; i++) {
        level1Data.push([res.data.level1[i][0], parseInt(res.data.level1[i][1])]);
      }
      this.setState({level1: level1Data})

      let level2Data = [['Sektor', 'Antall']];
      for (var i = 0; i < res.data.level2.length; i++) {
        level2Data.push([res.data.level2[i][0], parseInt(res.data.level2[i][1])]);
      }
      this.setState({level2: level2Data})

      let userlevel1Data = [['Sektor', 'Antall']];
      for (var i = 0; i < res.data.userLevel1.length; i++) {
        userlevel1Data.push([res.data.userLevel1[i][0], parseInt(res.data.userLevel1[i][1])]);
      }
      this.setState({userlevel1: userlevel1Data})

      let userlevel2Data = [['Sektor', 'Antall']];
      for (var i = 0; i < res.data.userLevel2.length; i++) {
        userlevel2Data.push([res.data.userLevel2[i][0], parseInt(res.data.userLevel2[i][1])]);
      }
      this.setState({userlevel2: userlevel2Data})


      let malelevel1Data = [['Sektor', 'Antall']];
      for (var i = 0; i < res.data.maleLevel1.length; i++) {
        malelevel1Data.push([res.data.maleLevel1[i][0], parseInt(res.data.maleLevel1[i][1])]);
      }
      this.setState({malelevel1: malelevel1Data})

      let femalelevel1Data = [['Sektor', 'Antall']];
      for (var i = 0; i < res.data.femaleLevel1.length; i++) {
        femalelevel1Data.push([res.data.femaleLevel1[i][0], parseInt(res.data.femaleLevel1[i][1])]);
      }
      this.setState({femalelevel1: femalelevel1Data})

      this.setState({posts: res.data.totalPosts});
      this.setState({navPosts: res.data.navPosts});
      this.setState({searches: res.data.searches});
      this.setState({applications: res.data.applications});
      this.setState({year: res.data.year});

    });

  }

  loadUserData() {
    axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
      let data = res.data;

      if(res.data.success == true) {
        this.setState({userdata: res.data});
        //this.setState({isLoggedIn: true});
      } else {
        //this.setState({isLoggedIn: false});
      }

      this.setState({loaded: true});

    })
  }



  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>
          <Card className="tcContainer">
            <Card.Body>
              <Card.Title>Statistikk</Card.Title>
              <Card.Subtitle className={`mb-2 text-muted`}></Card.Subtitle>
              <Card.Text>

              <div className='StatisticsMenu'>
                <div>
                  <button onClick={(e) => {this.loadStatistics("2020")}}>2020</button>
                  <button onClick={(e) => {this.loadStatistics("2021")}}>2021</button>
                  <button onClick={(e) => {this.loadStatistics("2022")}}>2022</button>
                </div>

                <div className='navMenu'>
                <label><input type="checkbox" name="checkbox" checked={this.state.showNav} onChange={() => {this.setState({showNav: !this.state.showNav}); this.loadStatistics(this.state.year, !this.state.showNav)}}/>Inkluder NAV i statistikk</label>
                </div>
              </div>
              
              <br /><br />

                <Chart
                  width={1000}
                  height={350}
                  chartType="Calendar"
                  loader={<div>Laster inn graf.</div>}
                  data={this.state.registrations}
                  options={{
                    title: 'Registreringer ' + this.state.year,
                  }}
                />

                <Chart
                  width={1000}
                  height={350}
                  chartType="Calendar"
                  loader={<div>Laster inn graf.</div>}
                  data={this.state.imports}
                  options={{
                    title: 'Nav importeringer ' + this.state.year,
                  }}
                />


                <div className="genderStatistics">
                  <div className="genderChart">
                    <Chart
                      width={'500px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={this.state.genders}
                      options={{
                        title: 'Kjønnsfordeling',
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>
                  <div className="userStatistics">
                    <b>Menn:</b><br />
                    {this.state.genders[2][1]}<br /><br />

                    <b>Kvinner:</b><br />
                    {this.state.genders[1][1]}<br /><br />

                    <b>Annet:</b><br />
                    {this.state.genders[3][1]}<br /><br />

                    <b>Totalt:</b><br />
                    {this.state.genders[3][1] + this.state.genders[2][1] + this.state.genders[1][1]}
                  </div>

                  <div className="sytemStatistics">
                    <b>Utlysninger:</b><br />
                    {this.state.posts}<br /><i>(Hvorav {this.state.navPosts} er NAV)</i><br /><br />

                    <b>Antall søk:</b><br />
                    {this.state.searches}<br /><br />

                    <b>Søknader:</b><br />
                    {this.state.applications}
                  </div>
                </div>


                <h1>Brukere</h1>
                <div className="genderStatistics">
                  <div className="genderChart">
                    <Chart
                      width={'450px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={this.state.userlevel1}
                      options={{
                        title: 'Sektor (Brukere)',
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>

                  <div className="userStatistics">
                    <Chart
                      width={'450px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={this.state.userlevel2}
                      options={{
                        title: 'Undersektor (Brukere)',
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>
              </div>

              <h1>Kjønn</h1>
              <div className="genderStatistics">
                <div className="genderChart">
                  <Chart
                    width={'450px'}
                    height={'300px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.malelevel1}
                    options={{
                      title: 'Sektor (Menn)',
                    }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                </div>

                <div className="userStatistics">
                  <Chart
                    width={'450px'}
                    height={'300px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.femalelevel1}
                    options={{
                      title: 'Sektor (Damer)',
                    }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                </div>
            </div>


                <h1>Utlysninger</h1>

                <div className="genderStatistics">
                  <div className="genderChart">
                    <Chart
                      width={'500px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={this.state.level1}
                      options={{
                        title: 'Sektor (Utlysninger)',
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>

                  <div className="userStatistics">
                    <Chart
                      width={'450px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={this.state.level2}
                      options={{
                        title: 'Undersektor (Utlysninger)',
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>
              </div>

              <div className="genderStatistics">
                <div className="genderChart">
                  {this.state.level1.map((level1) => (
                    <div>
                      <b>{level1[0]}:</b><br />
                      {level1[1]}<br />
                    </div>
                  ))}
                </div>

                <div className="userStatistics">
                {this.state.level2.map((level2) => (
                  <div>
                    <b>{level2[0]}:</b><br />
                    {level2[1]}<br />
                  </div>
                ))}
                </div>
            </div>

            </Card.Text>
          </Card.Body>
        </Card>
        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
