import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import "./home.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faSpinner, faMap } from '@fortawesome/free-solid-svg-icons'

import {BriefcaseIcon} from 'react-octicons'

import axios from "axios";

import AddInfo from "../../components/addinfo/addinfo.jsx";

import Map from "../map/map.jsx";

export default class Home extends Component<Props> {
  state = {
    isLoggedIn: false,
    results: {
      success: true,
      list:[],
      posts:0,
      companies:0
    },
    search: "",
    searchTerm: "",
    toggleFilter: false,
    counties: [],
    municipalities: [],
    categories: [],

    searched: false,
    loadingmore: false,

    county: [],
    chosenMunicipalities: [],
    page: 1,
    lastPage: false,

    countyCount: [],
    municipalityCount: [],


    chosenMainCats: [],
    chosenSubCats: [],

    sector: "",
    hiretype: "",
    source: "",
    profileStatus: {
      missing_experience: true,
      missing_profile_picture: true
    },
    posts: 0,
    userdata: {},

    showMap: false,
    isAnon: 0,
  }

  constructor(props) {
    super(props);
    this.openPosition = this.openPosition.bind(this);
    this.search = this.search.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.getCounties = this.getCounties.bind(this);
    this.chooseCounty = this.chooseCounty.bind(this);
    this.chooseMunicipality = this.chooseMunicipality.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
    this.loadCategories = this.loadCategories.bind(this);

    let event = new Event('loadUserData');
    window.addEventListener('loadUserData', this.loadUserData, false);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }

    if(this.props.match.params.query !== undefined) {
      this.setState({search: this.props.match.params.query});
      this.search(this.props.match.params.query);
    } else {
      this.search(false);
    }

    this.getCounties();
    this.detectProfile();
    this.loadUserData();
    this.loadCategories();
  }

  openPosition(id, job) {
    this.props.history.push({
      pathname: "/position/"+id,
      job: job
    })
  }

  detectProfile() {
    axios.get("https://l16.vixo.no/api/public/get/tips").then(res => {
      console.log(res.data);
      this.setState({profileStatus: res.data});
    });
  }

  loadUserData() {
    axios.get("https://l16.vixo.no/api/public/get/user").then(res => {
      let data = res.data;

      if(res.data.success == true) {
        this.setState({userdata: res.data});
        //this.setState({isLoggedIn: true});
      } else {
        //this.setState({isLoggedIn: false});
      }

      this.setState({loaded: true});

    })
  }

  search(query = false) {
    this.setState({searched: false});
    this.setState({page: 1});
    this.setState({results: {
      success: true,
      list:[],
      posts:0,
      companies:0
    }})
    console.log("Dataa: " + JSON.stringify(query))
    if(query !== false) {
      this.loadPage(1, query);
    } else {
      this.loadPage(1, false);
    }
  }

  nextPage() {
    this.setState({loadingmore: true});
    let nextPage = this.state.page + 1;
    this.setState({page: nextPage});
    this.loadPage(nextPage, false);
  }

  loadCategories() {
    axios.get("https://l16.vixo.no/api/public/v2/get/categories").then(res => {
      this.setState({categories: res.data});
    })
  }

  loadPageDeprecated(page) {

    this.setState({searchTerm: this.state.search});
    let data = new FormData();
    data.append("query", this.state.search);
    data.append("sector", this.state.sector);
    data.append("hiretype", this.state.hiretype);
    data.append("source", this.state.source);
    data.append("tansitmethod", "NONE");
    data.append("traffic", "TRAFFIC_FREE");
    data.append("traveltime", "");
    data.append("category", "");
    data.append("compensation", "NONE");
    data.append("county", this.state.county);
    data.append("municipalities", this.state.chosenMunicipalities);
    data.append("page", page);
    data.append("level1", this.state.chosenMainCats);
    data.append("level2", this.state.chosenSubCats);

    axios.post("https://l16.vixo.no/api/public/v2/get/user/jobs", data).then(res => {
      let arr = this.state.results;
      arr.list = arr.list.concat(res.data.list);
      this.setState({results: arr});
      if(res.data.list.length < 20) {
        this.setState({lastPage: true});
      }

      this.setState({countyCount: res.data.county});
      this.setState({municipalityCount: res.data.municipality});
      this.setState({posts: res.data.posts});

      this.setState({searched: true});
      this.setState({loadingmore: false});
    });
  }

  loadPage(page, query = false) {
    this.setState({searchTerm: this.state.search});

    let params = {
      query: this.state.search,
      sort: {
          "nav": "asc"
        },
      filters : {
        all: [
        ]
      },
      page: {
        current: page,
        size: 20
      }
    }

    if(query != false) {
      params.query = query;
      console.log("We changed query.");
    }

    if(this.state.chosenMainCats.length != 0) {
      params.filters.all.push({"cat1": this.state.chosenMainCats});
    }

    if(this.state.chosenSubCats.length != 0) {
      params.filters.all.push({"cat2": this.state.chosenSubCats});
    }

    if(this.state.chosenMunicipalities.length != 0) {
      params.filters.all.push({"municipality": this.state.chosenMunicipalities});
    }

    if(this.state.county.length != 0) {
      params.filters.all.push({"county": this.state.county});
    }

    if(this.state.sector != "0" && this.state.sector != "") {
      params.filters.all.push({"sector": this.state.sector});
    }

    axios.post("https://enterprise-search-deployment-5aff60.ent.europe-north1.gcp.elastic-cloud.com/api/as/v1/engines/theconnectorposts/search", params, {
      headers: {
        Authorization: 'Bearer search-c1br28yosdu1x9r2qtxinnn7', //the token is a variable which holds the token
        'Content-Type': 'application/json'
      }
    }).then(res => {
      let arr = this.state.results;
      arr.list = arr.list.concat(res.data.results);

      this.setState({searched: true});
      this.setState({loadingmore: false});
    });
  }

  toggleFilter() {
    if(this.state.showFilter == true) {
      this.setState({showFilter: false});
    } else {
      this.setState({showFilter: true});
    }
  }

  getCounties() {
    axios.get("https://l16.vixo.no/api/public/get/location/counties").then(res => {
      this.setState({counties: res.data});
    })
  }

  chooseCounty(id) {
    if(this.state.county == id) {
      this.setState({county: 0});
    } else {
      this.setState({county: id});
    }
    axios.get("https://l16.vixo.no/api/public/get/location/municipalities?county=" + id).then(res => {
      this.setState({municipalities: res.data});
      this.setState({chosenMunicipalities: []});
    })
  }

  chooseMunicipality(id) {
    let chosen = this.state.chosenMunicipalities;

    if(chosen.includes(id)) {
      chosen.remove(id);
      this.setState({chosenMunicipalities: chosen});
    } else {
      chosen.push(id);
      this.setState({chosenMunicipalities: chosen});
    }
  }

  getCountyCount(id) {
    if(typeof this.state.countyCount[id] !== "undefined") {
      return this.state.countyCount[id];
    } else {
      return 0;
    }
  }

  getMunicipalityCount(id) {
    if(typeof this.state.municipalityCount[id] !== "undefined") {
      return this.state.municipalityCount[id];
    } else {
      return 0;
    }
  }


  chooseMainCat(id) {
    let chosen = this.state.chosenMainCats;

    if(chosen.includes(id)) {
      chosen.remove(id);
      this.setState({chosenMainCats: chosen});
    } else {
      chosen.push(id);
      this.setState({chosenMainCats: chosen});
    }
  }

  chooseSubCat(id) {
    let chosen = this.state.chosenSubCats;

    if(chosen.includes(id)) {
      chosen.remove(id);
      this.setState({chosenSubCats: chosen});
    } else {
      chosen.push(id);
      this.setState({chosenSubCats: chosen});
    }
  }

  //<div className={`searchWrapper ${this.state.searched == true && (this.state.profileStatus.missing_experience == true || this.state.profileStatus.missing_profile_picture == true) ? 'hidden':''}`}>
  render() {
    if(this.state.isLoggedIn == false) {
      return(
        <div>

          <Card className="tcContainer">
            <Card.Body>

              <div className="mapBtnContainer">
                <Button variant="warning" onClick={(e) => {this.setState({showMap: true})}}>
                  <FontAwesomeIcon icon={faMap} /> Kart
                </Button>
              </div>


              <Card.Title>Utlysninger</Card.Title>
              <Card.Subtitle className={`mb-2 text-muted ${this.state.searchTerm == "" ? 'hidden':''}`}>Viser {this.state.results.list.length} treff på "<i>{this.state.searchTerm}</i>"</Card.Subtitle>
              <Card.Text>

                <div className={`searchWrapper`}>

                  <div className="searchContainer">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control type="text" placeholder="F.eks IT, Apputvikler, Account manager, Olje" value={this.state.search} onChange={(e) => {this.setState({search: e.target.value})}} />
                    </Form.Group>
                  </div>

                  <div className="searchBtn">
                    <Button variant="primary" onClick={(e) => {this.search();}}>
                      Søk
                    </Button><br />
                    <div className="filter noselect" onClick={this.toggleFilter}>
                      Filter <span className={this.state.showFilter?'':'hidden'}>↓</span><span className={this.state.showFilter?'hidden':''}>↑</span>
                    </div>
                  </div>

                </div>
                <div className="filterWrapper">
                  <div className={`filterMenu ${this.state.showFilter ? '':'hidden'}`}>
                    <h4>Fylke:</h4>
                    <div className="counties">
                      {this.state.counties.map((county) => (
                        <div className={`county noselect ${this.state.county == county.id ? 'active':''} ${this.state.county != county.id && this.state.county != 0 ? 'notSelected':''}`} onClick={(e) => { this.chooseCounty(county.id) }}>{county.county} ({this.getCountyCount(county.id)})</div>
                      ))}
                    </div>

                    <h4 className={this.state.county != 0 ? '':'hidden'}>Kommune:</h4>
                    <div className={`municipalities counties ${this.state.county != 0 ? '':'hidden'}`}>
                      {this.state.municipalities.map((municipality) => (
                        <div className={`county noselect ${this.state.chosenMunicipalities.includes(municipality.id) ? 'active' : ''} ${!this.state.chosenMunicipalities.includes(municipality.id) && this.state.chosenMunicipalities.length != 0 ? 'notSelected' : ''} ${this.getMunicipalityCount(municipality.id) != 0 ? 'glow':'notSelected'}`} onClick={(e) => { this.chooseMunicipality(municipality.id) }}>{municipality.municipality} ({this.getMunicipalityCount(municipality.id)})</div>
                      ))}
                    </div>
                  </div>


                  <div className={`filterSettings ${this.state.showFilter ? '':'hidden'}`}>
                    <h4>Filter:</h4>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Sektor:</Form.Label>
                      <Form.Control as="select" value={this.state.sector} onChange={(e) => {this.setState({sector: e.target.value})}}>
                        <option value="">Alle</option>
                        <option value="Offentlig">Offentlig</option>
                        <option value="Privat">Privat</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlSelect1" className="hidden">
                      <Form.Label>Stillingstype:</Form.Label>
                      <Form.Control as="select" value={this.state.hiretype} onChange={(e) => {this.setState({hiretype: e.target.value})}}>
                        <option value="">Alle</option>
                        <option value="1">Heltid</option>
                        <option value="2">Deltid</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlSelect1" className="hidden">
                      <Form.Label>Kilde:</Form.Label>
                      <Form.Control as="select" value={this.state.source} onChange={(e) => {this.setState({source: e.target.value})}}>
                        <option value="">Alle</option>
                        <option value="0">The Connector</option>
                        <option value="1">NAV</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Vurderingsgrunnlag:</Form.Label>
                      <Form.Control as="select" value={this.state.sector} onChange={(e) => {this.setState({isAnon: e.target.value})}}>
                        <option value="0">Alle</option>
                        <option value="1">Anonymisert søknadsliste</option>
                      </Form.Control>
                    </Form.Group>

                  </div>

                </div>
                <div className="contentWrapper">
                  <div className="contentBox">
                    <ListGroup className={this.state.searched == true ? '':'hidden'}>

                           {this.state.results.list.map((item) => (
                                <ListGroup.Item className="listGroupSegments" onClick={(e) => {this.openPosition(item._meta.id, item)}}>
                                  <div className={`lgLogo ${item.nav.raw == "1" ? 'hidden':''}`} style={{backgroundImage: `url(https://l16.vixo.no/api/public/get/image/${item._meta.id})`}}></div>
                                  <div className={`lgLogo ${item.nav.raw == "1" ? '':'hidden'}`}><FontAwesomeIcon icon={faSuitcase} /></div>

                                  <div className="lgContent">
                                    <h5>{item.title.raw}</h5>
                                    <p className={item.description.raw.length == 0 ? 'hidden':''}>{item.description.raw}</p>
                                    <p>{item.company.raw}</p>
                                  </div>
                                  {
                                  /*<div className={`lgAction ${item.isAd == false ? '' : 'hidden'}`}>
                                    Frist: {item.due == '01-01-1970' ? 'Ukjent' : item.due}
                                  </div>
                                  */}
                                </ListGroup.Item>
                              )
                            )}

                    </ListGroup>
                  </div>

                  <div className={`catBox ${this.state.showFilter ? '':'mobileHidden'}`}>
                  <h3>Kategori</h3>
                    <div className={`catContainer ${this.state.showFilter ? '':'mobileHidden'}`}>
                      {this.state.categories.map((category) => (
                        <div>
                        <Form.Group>
                          <Form.Check type="checkbox" label={category.name} checked={this.state.chosenMainCats.includes(category.id)} onChange={(e) => {this.chooseMainCat(category.id)}} />
                        </Form.Group>

                          <div className={`subcat ${this.state.chosenMainCats.includes(category.id) ? '' : 'hidden'}`}>
                            {category.children.map((subCat) => (
                              <div>
                              <Form.Group>
                                <Form.Check type="checkbox" label={subCat.name} checked={this.state.chosenSubCats.includes(subCat.id)} onChange={(e) => {this.chooseSubCat(subCat.id)}} />
                              </Form.Group>
                              </div>
                            ))}
                          </div>

                        </div>
                      ))}
                    </div>
                  </div>


                </div>

                <div className={`loading ${this.state.loadingmore == true ? '':'hidden'}`} style={{"textAlign": "center"}}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>

                <div className={`loadMore ${this.state.results.list.length == 0 ? 'hidden':''} ${this.state.lastPage == true ? 'hidden':''} ${this.state.searched == true && (this.state.profileStatus.missing_experience == true || this.state.profileStatus.missing_profile_picture == true) ? 'hidden':''}`} onClick={this.nextPage}>Last inn fler</div>

                <div className={this.state.results.list.length == 0 && this.state.searched == true ? '':'hidden'} style={{"textAlign": "center"}}>
                  <h3>Pr. dags dato finnes det ikke stillinger for ditt søk!</h3>
                  <p>Kanskje du ønsker å se over profilen din, en ryddig og oppdatert profil er viktig!</p>
                  <p style={{"paddingLeft": "20%", "paddingRight": "20%"}}>Du kan motta meldinger selvom det ikke er noen aktive utlysninger i portalen, hodejegere kan søke etter kompetanse på stillinger som ikke ligger offentlig.</p>
                </div>

                <div className={this.state.searched == true && (this.state.profileStatus.missing_experience == true || this.state.profileStatus.missing_profile_picture == true) ? 'hidden':'hidden'} style={{"textAlign": "center"}}>
                  <h3>Du har ikke fylt ut profilen!</h3>
                  <p>Du går glipp av <b>{this.state.posts}</b> utlysninger!</p>
                  <p><Link to={`/profile/${this.state.userdata.url}`}>Trykk her for å gå til din profil!</Link><br /></p>
                </div>

                <div className={`loading ${this.state.results.list.length == 0 && this.state.searched == false ? '':'hidden'}`} style={{"textAlign": "center"}}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

          <AddInfo />

          <div className={this.state.showMap ? '' : 'hidden'}>
            <Map home={this} />
          </div>

        </div>
      );
    } else {
      return(
        <div>
          123
        </div>
      );
    }
  }
}
