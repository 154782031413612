import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './login.css';

export default class Login extends Component<Props> {

  state = {
    email: "",
    password: "",
    success: true,
    msg: ""
  }

  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") !== null) {
      //check if token is valid
      axios.defaults.headers.common["token"] = localStorage.getItem("token");
      let data = new FormData();
      data.append("token", localStorage.getItem("token"));
      axios.get("https://l16.vixo.no/api/public/get/user", data).then(res => {
        if(res.data.success) {
          if(res.data.role === "admin") {
            this.props.history.push("/admin/statistic");
          } else if(res.data.role == "recruiter") {
            this.props.history.push("/recruiter/positions");
          } else {
            this.props.history.push("/dashboard");
          }
        }
      });
    }
  }

  login() {
    let data = new FormData();
    data.append("email", this.state.email);
    data.append("password", this.state.password);

    axios.post('https://l16.vixo.no/api/public/login', data).then(res => {
      let data = res.data;
      if(data.success == true) {
        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("token", data.token);

        axios.defaults.headers.common["token"] = data.token;
        let event = new Event('loadUserData');
        window.dispatchEvent(event);

        if(data.role === "admin") {
          this.props.history.push("/admin/statistic");
        } else if(data.role == "recruiter") {
          this.props.history.push("/recruiter/positions");
        } else {
          this.props.history.push("/dashboard");
        }
      } else {
        this.setState({success: false});
        this.setState({msg: data.msg});
      }
    })
  }

  stateChange(e){
    console.log(e);
  }

  openRegister() {
    window.open("https://www.theconnector.no/register/",'_blank');
  }

  render() {
    return(
      <div className="login-wrapper">
        <Card style={{ width: '100%' }}>
          <Card.Body>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Epost</Form.Label>
                <Form.Control type="email" placeholder="Din epost" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Passord</Form.Label>
                <Form.Control type="password" placeholder="**********" value={this.state.password} onChange={(e) => {this.setState({password: e.target.value})}} />
              </Form.Group>
              <p className={`${this.state.success?'hidden':''}`}>{this.state.msg}</p>
              <Button variant="primary" type="submit" onClick={this.login} className="loginBtn">
                Logg på!
              </Button><br />
              <div className="register">
                Har du ikke konto?<br />
                <Link onClick={this.openRegister}>Registrer deg nå!</Link>
                <Link onClick={(e) => {this.props.history.push("/forgot")}}>Glemt passord?</Link>
              </div>
          </Card.Body>
        </Card>

      </div>
    );
  }
}
