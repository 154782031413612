import React, { Component } from 'react';
import { Card, Button, ListGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './forgot.css';

export default class Forget extends Component<Props> {

  state = {
    email: "",
    success: false,
    msg: ""
  }

  constructor(props) {
    super(props);

    this.forgot = this.forgot.bind(this);
  }

  componentDidMount() {
  }

  forgot() {
    let data = new FormData();
    data.append("email", this.state.email);

    axios.post('https://l16.vixo.no/api/public/recover/password', data).then(res => {
      this.setState({success: true});
    })
  }

  stateChange(e){
    console.log(e);
  }

  render() {
    return(
      <div className="login-wrapper">
        <Card style={{ width: '100%' }}>
          <Card.Body>
              <p className={`${this.state.success == true ?'':'hidden'}`}>Hvis eposten er registrert vil du motta en epost med instruksjoner innen ett minutt.</p>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Epost</Form.Label>
                <Form.Control type="email" placeholder="olanordmann@example.com" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} />
              </Form.Group>

              <Button variant="primary" type="submit" onClick={this.forgot} className="loginBtn">
                Send
              </Button><br />
          </Card.Body>
        </Card>

      </div>
    );
  }
}
