import React, { Component } from 'react';
import { Card, Button, ListGroup, Form, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import swal from 'sweetalert';

import axios from "axios";

export default class BlockCompany extends Component<Props> {
  state = {
    isLoggedIn: false,
    blocked: [],
    companies: [],
    showCompanyPicker: false
  }

  constructor(props) {
    super(props);
    this.getBlocked = this.getBlocked.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.SearchCompany = this.SearchCompany.bind(this);
    this.block = this.block.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem("token") === null) {
      //this.props.history.push("/login");
    }
    this.getBlocked();
  }

  getBlocked(page) {
    axios.get("https://l16.vixo.no/api/public/block/list").then(res => {
      this.setState({blocked: res.data.list});
    });
  }

  SearchCompany(value) {
    console.log(value);
    axios.get("https://data.brreg.no/enhetsregisteret/api/enheter?navn=" + value).then(res => {
      if(typeof res.data._embedded !== 'undefined') {
        this.setState({companies: res.data._embedded.enheter});
      }
    });
  }

  block(item) {
    this.setState({showCompanyPicker: false});
    console.log("BLOCK: ");
    console.log(item);

    let data = new FormData();
    data.append('orgnr', item.organisasjonsnummer);
    data.append('name', item.navn);

    axios.post("https://l16.vixo.no/api/public/block/company", data).then(res => {
      console.log(res.data);
      if(res.data.success == true) {
        this.getBlocked();
      } else {
        swal('Oida!', 'Noe uventet har oppstått, vennligst prøv igjen senere!', 'error');
      }
    });
  }

  unblock(item) {

    let data = new FormData();
    data.append('orgnr', item.orgnr);
    data.append('name', item.name);

    axios.post("https://l16.vixo.no/api/public/block/unblock", data).then(res => {
      console.log(res.data);
      if(res.data.success == true) {
        this.getBlocked();
      } else {
        swal('Oida!', 'Noe uventet har oppstått, vennligst prøv igjen senere!', 'error');
      }
    });
  }

  handleClose() {
    this.setState({showCompanyPicker: false});
  }

  render() {
      return(
        <div>

          <Card className="tcContainer">
            <Card.Body>
              <div className="addBtnContainer">
                <Card.Title className="addContent">Blokkerte firmaer</Card.Title>
                <Button variant="primary" onClick={(e) => {this.setState({showCompanyPicker: true})}} className="addButton">Legg til</Button>
              </div>
              <Card.Text>

                <ListGroup>

                       {this.state.blocked.map((item) => (
                            <ListGroup.Item className="listGroupSegmentsNoImage">
                              <div className="lgContent">
                                <h4>{item.name}</h4>
                                <p>{item.orgnr}</p>
                              </div>

                              <div className={`lgAction`}>
                                <Button variant="primary" onClick={(e) => {this.unblock(item);}}>Fjern</Button>
                              </div>
                            </ListGroup.Item>
                          )
                        )}

                </ListGroup>

              </Card.Text>
            </Card.Body>
          </Card>


          <Modal show={this.state.showCompanyPicker} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Velg bedrift</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control type="text" placeholder="Olas bakeri" onChange={(e) => {
                      this.SearchCompany(e.target.value);
                    }} />
                </Form.Group>

                <ListGroup>
                       {this.state.companies.map((item) => (
                            <ListGroup.Item onClick={(e) => {this.block(item);}}>
                              <div className="lgContent">
                                <h4>{item.navn}</h4>
                                <p>{item.organisasjonsnummer}</p>
                              </div>
                            </ListGroup.Item>
                          )
                        )}
                </ListGroup>
              </Form>
            </Modal.Body>
          </Modal>

        </div>
      );
  }
}
